// eslint-disable-next-line import/no-anonymous-default-export
export default {
  default: "/",
  home: "/dashboard",
  //auth
  login: "/login",
  forgot: "/forgot",
  verifyemail: "/verifyemail",
  verifyaccount: "/set-password",
  resetpassword: "/reset-password",
  successmsg: "/successmsg",
};
