import React from 'react'

export default function invalidUrl() {
	return (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xmlSpace="preserve">
  <g id="Floor">
  </g>
  <g id="Shadow">
    <path id="Floor_3_" fill="#F0F0F0" d="M85.927,228.663c-92.003,53.118-92.003,139.24,0.001,192.358s241.172,53.119,333.175,0
		s92.003-139.24-0.001-192.358S177.931,175.544,85.927,228.663z" />
    <path id="Shadow_2_" fill="#E0E0E0" d="M243.821,347.848c-31.726,18.317-31.726,48.016,0.001,66.333
		c31.727,18.317,83.165,18.317,114.892,0c31.726-18.317,31.726-48.016-0.001-66.333C326.986,329.531,275.549,329.53,243.821,347.848
		z" />
  </g>
  <g id="Plants_1_">
    <g>
      <g opacity="0.1">
        <polygon points="391.728,363.095 265.361,436.557 54.264,314.668 180.618,241.25 			" />
      </g>
      <g>
        <path fill="#888889" d="M370.111,360.094c-1.49,5.556-97.03,56.434-97.03,56.434c-6.19,3.581-18.593,3.023-24.834-0.596
				c-43.194-24.941-169.398-98.587-169.398-98.587c-6.21-3.589-8.102-12.9-1.937-16.48l99.533-54.589
				C176.445,246.274,384.227,308.039,370.111,360.094z" />
      </g>
      <g>
        <path fill="#D3D3D3" d="M370.813,355.713l-97.732,56.81c-6.19,3.572-18.593,3.041-24.834-0.562
				c-43.194-24.96-170.483-99.228-170.483-99.228c-6.254-3.635-6.271-9.44-0.092-13.024l98.773-57.416L370.813,355.713z" />
      </g>
      <path fill="#888889" d="M396.586,219.626c-0.089-0.719-0.211-1.458-0.363-2.201c-0.076-0.21-0.104-0.417-0.171-0.641
			c-0.202-0.749-0.42-1.523-0.727-2.233c-0.017-0.125-0.073-0.273-0.108-0.359c-0.263-0.627-0.56-1.243-0.879-1.829
			c-0.177-0.306-0.302-0.56-0.507-0.85c-0.313-0.494-0.622-0.981-0.971-1.39c-0.056-0.138-0.192-0.306-0.258-0.4
			c-0.458-0.615-0.962-1.104-1.464-1.587c-0.166-0.126-0.25-0.209-0.42-0.338c-0.555-0.494-1.129-0.92-1.767-1.283l-171.647-99.083
			c-0.658-0.385-1.329-0.651-2.006-0.857c-0.239-0.07-0.424-0.127-0.678-0.177c-0.299-0.092-0.615-0.132-0.921-0.179
			c-0.271-0.042-0.546-0.042-0.821-0.042c-0.196,0-0.425,0-0.608,0c-0.437,0.042-0.831,0.089-1.228,0.194l4.871-1.202
			c0.279-0.088,0.57-0.154,0.855-0.158c0.133,0,0.255,0,0.386,0c0.208-0.033,0.36-0.033,0.616-0.033c0.126,0,0.259,0,0.398,0
			c0.116,0,0.256,0.033,0.417,0.037c0.261,0.048,0.568,0.101,0.883,0.173c0.167,0.02,0.274,0.02,0.446,0.077
			c0.084,0.026,0.214,0.072,0.256,0.125c0.676,0.182,1.379,0.439,2.018,0.839l171.642,99.099c0.634,0.35,1.22,0.804,1.771,1.307
			c0.115,0.089,0.248,0.183,0.34,0.269c0.517,0.492,1.059,1.06,1.513,1.626c0.102,0.113,0.179,0.217,0.257,0.332
			c0.178,0.228,0.4,0.455,0.578,0.73c0.166,0.225,0.252,0.531,0.423,0.761c0.194,0.258,0.347,0.527,0.492,0.793
			c0.068,0.146,0.148,0.269,0.212,0.402c0.256,0.505,0.508,1.039,0.674,1.583c0.056,0.068,0.091,0.129,0.109,0.168
			c0,0.026,0,0.026,0,0.026c0.263,0.57,0.451,1.165,0.6,1.753c0.057,0.183,0.065,0.417,0.124,0.585
			c0.06,0.182,0.09,0.372,0.129,0.518c0.079,0.236,0.13,0.459,0.149,0.68c0.132,0.533,0.183,1.043,0.236,1.581
			c0,0.085,0.044,0.181,0.062,0.253c0,0.004,0,0.055,0,0.097c0.066,0.763,0.066,1.525,0.066,2.295c0,0.055-0.043,0.113-0.043,0.157
			c-0.023,0.99-0.175,1.928-0.36,2.901l-31.051,135.949l-4.121,2.399l30.314-137.173c0.192-0.933,0.315-1.935,0.363-2.918
			c0-0.827,0-1.692-0.048-2.529C396.586,219.795,396.586,219.709,396.586,219.626z" />
      <g>
        <path fill="#D3D3D3" d="M217.305,107.431c-5.576-3.242-11.451-0.109-13.051,7.038l-27.809,127.825L367.008,357.96l29.297-132.64
				c1.584-7.125-1.717-15.543-7.353-18.806L217.305,107.431z" />
      </g>
      <polygon fill="#8FBAF9" points="389.59,229.068 208.074,125.474 183.997,235.291 363.113,345.141 		" />
      <g>
        <g>
          <path fill="#FFFFFF" d="M169.637,260.202l173.036,99.914l-57.748,33.603l-173.086-99.921L169.637,260.202z M243.816,364.797
					l7.741-4.535l-80.575-46.487l-7.771,4.5L243.816,364.797L243.816,364.797z M192.586,301.198l-7.784,4.531l7.144,4.116
					l7.773-4.518L192.586,301.198L192.586,301.198z M167.469,295.701l7.773-4.502l-7.131-4.146l-7.786,4.563L167.469,295.701
					L167.469,295.701z M265.4,352.262l7.789-4.533l-7.148-4.124l-7.777,4.526L265.4,352.262L265.4,352.262z M241.564,329.47
					l-7.784,4.526l7.14,4.138l7.771-4.553L241.564,329.47L241.564,329.47z M289.845,366.384l7.795-4.514l-7.127-4.14l-7.784,4.534
					L289.845,366.384L289.845,366.384z M209.293,319.879l7.096,4.111l7.813-4.52l-7.118-4.146L209.293,319.879L209.293,319.879z
					 M208.896,310.621l-7.74,4.522l7.139,4.128l7.771-4.496L208.896,310.621L208.896,310.621z M306.192,375.811l7.796-4.518
					l-7.121-4.128l-7.825,4.522L306.192,375.811L306.192,375.811z M159.281,291.017l7.807-4.518l-7.12-4.157l-7.772,4.538
					L159.281,291.017L159.281,291.017z M233.426,324.777l-7.774,4.494l7.135,4.142l7.759-4.52L233.426,324.777L233.426,324.777z
					 M183.773,305.124l7.795-4.492l-7.13-4.135l-7.791,4.522L183.773,305.124L183.773,305.124z M257.898,338.908l-7.789,4.502
					l7.125,4.129l7.785-4.507L257.898,338.908L257.898,338.908z M281.709,361.658l7.802-4.498l-7.132-4.104l-7.807,4.487
					L281.709,361.658L281.709,361.658z M268.292,378.938l7.758-4.502l-7.159-4.135l-7.734,4.524L268.292,378.938L268.292,378.938z
					 M156.675,301.972l7.772-4.48l-7.15-4.155l-7.769,4.518L156.675,301.972L156.675,301.972z M230.731,335.771l-7.732,4.512
					l7.133,4.104l7.756-4.487L230.731,335.771L230.731,335.771z M203.364,294.938l-7.722,4.481l7.118,4.142l7.753-4.502
					L203.364,294.938L203.364,294.938z M178.275,289.413l7.765-4.504l-7.14-4.116l-7.747,4.5L178.275,289.413L178.275,289.413z
					 M213.407,325.732l-7.173-4.096l-7.764,4.502l7.165,4.131L213.407,325.732L213.407,325.732z M220.13,313.552l7.118,4.162
					l7.789-4.521l-7.167-4.131L220.13,313.552L220.13,313.552z M181.759,307.476L174,311.991l7.152,4.128l7.758-4.528
					L181.759,307.476L181.759,307.476z M145.883,308.289l7.776-4.544l-7.155-4.1l-7.769,4.502L145.883,308.289L145.883,308.289z
					 M252.373,323.199l-7.771,4.491l7.103,4.158l7.8-4.507L252.373,323.199L252.373,323.199z M279.073,372.655l7.761-4.494
					l-7.133-4.146l-7.743,4.54L279.073,372.655L279.073,372.655z M254.607,358.526l7.771-4.492l-7.144-4.122l-7.794,4.494
					L254.607,358.526L254.607,358.526z M276.211,345.974l7.737-4.527l-7.097-4.111l-7.773,4.509L276.211,345.974L276.211,345.974z
					 M300.686,360.094l7.791-4.499l-7.17-4.144l-7.728,4.507L300.686,360.094L300.686,360.094z M219.725,304.345l-7.775,4.527
					l7.154,4.115l7.768-4.52L219.725,304.345L219.725,304.345z M270.93,367.984l7.764-4.549l-7.16-4.108l-7.75,4.484L270.93,367.984
					L270.93,367.984z M292.529,355.41l7.769-4.503l-7.127-4.15l-7.793,4.522L292.529,355.41L292.529,355.41z M222.598,331.063
					l-7.766,4.482l7.146,4.14l7.784-4.513L222.598,331.063L222.598,331.063z M194.619,298.844l7.74-4.514l-7.119-4.085l-7.776,4.505
					L194.619,298.844L194.619,298.844z M244.194,318.482l-7.767,4.498l7.164,4.14l7.741-4.491L244.194,318.482L244.194,318.482z
					 M173.008,311.418l7.763-4.505l-7.151-4.129l-7.768,4.518L173.008,311.418L173.008,311.418z M198.082,316.916l-7.749,4.502
					l7.146,4.125l7.759-4.488L198.082,316.916L198.082,316.916z M162.224,317.713l7.737-4.521l-7.164-4.131l-7.729,4.49
					L162.224,317.713L162.224,317.713z M247.074,345.191l-7.767,4.498l7.151,4.116l7.753-4.502L247.074,345.191L247.074,345.191z
					 M268.696,332.602l-7.792,4.533l7.162,4.13l7.76-4.537L268.696,332.602L268.696,332.602z M284.62,388.38l7.769-4.536
					l-7.168-4.122l-7.74,4.495L284.62,388.38L284.62,388.38z M137.729,303.561l7.756-4.502l-7.151-4.121l-7.758,4.516
					L137.729,303.561L137.729,303.561z M148.523,297.275l7.764-4.498l-7.143-4.153l-7.764,4.542L148.523,297.275L148.523,297.275z
					 M295.419,382.086l7.75-4.511l-7.129-4.133l-7.775,4.52L295.419,382.086L295.419,382.086z M170.131,284.725l7.752-4.527
					l-7.121-4.113l-7.77,4.526L170.131,284.725L170.131,284.725z M260.146,374.241l7.754-4.515l-7.156-4.138l-7.781,4.499
					L260.146,374.241L260.146,374.241z M317.039,369.512l7.734-4.485l-7.157-4.122l-7.746,4.511L317.039,369.512L317.039,369.512z
					 M151.115,286.27l7.821-4.514l-7.144-4.109l-7.77,4.509L151.115,286.27L151.115,286.27z M249.729,334.195l-7.772,4.518
					l7.094,4.084l7.781-4.489L249.729,334.195L249.729,334.195z M273.558,356.942l7.784-4.511l-7.111-4.112l-7.799,4.5
					L273.558,356.942L273.558,356.942z M175.604,300.413l7.808-4.513l-7.132-4.109l-7.772,4.511L175.604,300.413L175.604,300.413z
					 M200.762,305.928l-7.769,4.513l7.097,4.135l7.788-4.552L200.762,305.928L200.762,305.928z M298.011,371.076l7.774-4.502
					l-7.097-4.115l-7.788,4.511L298.011,371.076L298.011,371.076z M217.477,324.603l7.098,4.072l7.762-4.507l-7.06-4.116
					L217.477,324.603L217.477,324.603z M308.82,364.797l7.786-4.535l-7.13-4.07l-7.73,4.498L308.82,364.797L308.82,364.797z
					 M140.359,292.556l7.767-4.494l-7.13-4.107l-7.762,4.49L140.359,292.556L140.359,292.556z M287.257,377.391l7.746-4.528
					l-7.13-4.101l-7.754,4.514L287.257,377.391L287.257,377.391z M189.934,312.192l-7.76,4.55l7.151,4.109l7.755-4.511
					L189.934,312.192L189.934,312.192z M186.44,294.132l7.741-4.496l-7.074-4.114l-7.808,4.498L186.44,294.132L186.44,294.132z
					 M211.582,299.644l-7.765,4.518l7.12,4.126l7.729-4.544L211.582,299.644L211.582,299.644z M251.954,369.512l7.746-4.485
					l-7.126-4.122l-7.756,4.511L251.954,369.512L251.954,369.512z M129.558,298.844l7.723-4.514l-7.085-4.085l-7.781,4.505
					L129.558,298.844L129.558,298.844z M262.789,363.241l7.726-4.516l-7.13-4.115l-7.77,4.517L262.789,363.241L262.789,363.241z
					 M164.847,306.719l7.75-4.543l-7.126-4.083l-7.78,4.504L164.847,306.719L164.847,306.719z M284.35,350.671l7.783-4.507
					l-7.101-4.098l-7.79,4.481L284.35,350.671L284.35,350.671z M276.464,383.659l7.716-4.531l-7.128-4.123l-7.721,4.525
					L276.464,383.659L276.464,383.659z M260.557,327.916l-7.789,4.494l7.105,4.111l7.766-4.482L260.557,327.916L260.557,327.916z
					 M154.008,312.946l7.787-4.478l-7.123-4.096l-7.762,4.5L154.008,312.946L154.008,312.946z M228.291,318.275l7.12,4.133
					l7.747-4.515l-7.103-4.118L228.291,318.275L228.291,318.275z M221.551,330.438l-7.134-4.096l-7.733,4.498l7.127,4.15
					L221.551,330.438L221.551,330.438z M161.96,280.012l7.729-4.518l-7.105-4.106l-7.777,4.487L161.96,280.012L161.96,280.012z
					 M238.905,340.47l-7.739,4.518l7.123,4.125l7.753-4.526L238.905,340.47L238.905,340.47z M231.549,300.632l-5.382,3.112
					l7.135,4.135l5.368-3.101L231.549,300.632L231.549,300.632z M283.218,330.438l-5.374,3.143l7.162,4.129l5.381-3.157
					L283.218,330.438L283.218,330.438z M182.615,272.349l-5.441,3.146l7.164,4.137l5.373-3.173L182.615,272.349L182.615,272.349z
					 M212.503,289.636l-5.38,3.141l7.088,4.104l5.447-3.11L212.503,289.636L212.503,289.636z M201.631,283.361l-5.408,3.138
					l7.142,4.138l5.425-3.134L201.631,283.361L201.631,283.361z M313.175,347.729l-5.432,3.136l7.158,4.138l5.373-3.124
					L313.175,347.729L313.175,347.729z M324.019,354.035l-5.345,3.125l7.137,4.146l5.361-3.173L324.019,354.035L324.019,354.035z
					 M295.854,343.985l5.401-3.143l-7.122-4.113l-5.386,3.171L295.854,343.985L295.854,343.985z M173.443,273.326l5.403-3.117
					l-7.095-4.12l-5.411,3.113L173.443,273.326L173.443,273.326z M242.455,306.914l-5.398,3.146l7.125,4.115l5.381-3.152
					L242.455,306.914L242.455,306.914z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon fill="#5D5D5D" points="226.871,308.468 219.104,312.987 211.949,308.872 219.725,304.372 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="169.689,275.495 161.96,280.012 154.807,275.875 162.584,271.388 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="202.359,294.331 194.619,298.844 187.464,294.75 195.24,290.246 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="180.771,306.914 173.008,311.418 165.852,307.303 173.619,302.785 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="178.847,270.209 173.443,273.326 166.341,269.202 171.752,266.089 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="189.711,276.458 184.338,279.632 177.174,275.495 182.615,272.349 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="177.883,280.198 170.131,284.725 162.992,280.611 170.762,276.085 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="158.937,281.756 151.115,286.27 144.023,282.156 151.793,277.647 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="186.04,284.908 178.275,289.413 171.153,285.293 178.9,280.792 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="208.789,287.503 203.364,290.637 196.223,286.499 201.631,283.361 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="167.088,286.499 159.281,291.017 152.195,286.88 159.968,282.342 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="148.126,288.062 140.359,292.556 133.234,288.445 140.996,283.955 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="194.182,289.636 186.44,294.132 179.3,290.021 187.107,285.522 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="175.242,291.199 167.469,295.701 160.325,291.616 168.111,287.052 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="219.658,293.771 214.211,296.881 207.123,292.777 212.503,289.636 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="156.287,292.777 148.523,297.275 141.381,293.166 149.145,288.624 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="137.28,294.331 129.558,298.844 122.414,294.75 130.195,290.246 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="183.411,295.9 175.604,300.413 168.507,296.302 176.279,291.791 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="164.447,297.491 156.675,301.972 149.528,297.854 157.297,293.336 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="210.514,299.059 202.761,303.561 195.643,299.419 203.364,294.938 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="191.568,300.632 183.773,305.124 176.647,301.019 184.438,296.497 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="172.597,302.175 164.847,306.719 157.69,302.597 165.471,298.092 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="238.67,304.778 233.302,307.879 226.167,303.744 231.549,300.632 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="218.666,303.744 210.938,308.289 203.817,304.162 211.582,299.644 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="199.719,305.327 191.945,309.845 184.802,305.729 192.586,301.198 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="161.795,308.468 154.008,312.946 146.91,308.872 154.672,304.372 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="249.563,311.022 244.182,314.174 237.057,310.059 242.455,306.914 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="207.878,310.024 200.09,314.576 192.993,310.441 200.762,305.928 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="188.91,311.591 181.152,316.12 174,311.991 181.759,307.476 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="235.037,313.193 227.248,317.713 220.13,313.552 227.87,309.062 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="169.961,313.193 162.224,317.713 155.067,313.552 162.797,309.062 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="197.08,316.341 189.325,320.852 182.174,316.743 189.934,312.192 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="243.158,317.893 235.411,322.408 228.291,318.275 236.056,313.774 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="224.202,319.47 216.389,323.99 209.293,319.879 217.084,315.324 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="205.237,321.055 197.479,325.542 190.333,321.418 198.082,316.916 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="251.333,322.629 243.592,327.12 236.428,322.98 244.194,318.482 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="232.336,324.167 224.574,328.674 217.477,324.603 225.276,320.052 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="213.407,325.732 205.636,330.27 198.471,326.139 206.234,321.636 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="259.504,327.341 251.704,331.848 244.602,327.69 252.373,323.199 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="240.545,328.893 232.786,333.413 225.651,329.271 233.426,324.777 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="221.551,330.438 213.811,334.991 206.684,330.84 214.417,326.342 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="267.639,332.038 259.873,336.521 252.768,332.41 260.557,327.916 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="290.387,334.553 285.006,337.71 277.844,333.581 283.218,330.438 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="248.691,333.581 240.92,338.134 233.78,333.996 241.564,329.47 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="229.762,335.172 221.978,339.686 214.832,335.546 222.598,331.063 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="275.826,336.729 268.066,341.266 260.904,337.135 268.696,332.602 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="256.832,338.309 249.051,342.798 241.957,338.713 249.729,334.195 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="301.255,340.842 295.854,343.985 288.747,339.9 294.133,336.729 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="237.888,339.9 230.132,344.387 222.999,340.283 230.731,335.771 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="283.948,341.447 276.211,345.974 269.078,341.845 276.852,337.336 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="265.02,343.032 257.234,347.539 250.109,343.41 257.898,338.908 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="246.042,344.586 238.289,349.113 231.166,344.988 238.905,340.47 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="292.133,346.164 284.35,350.671 277.242,346.547 285.032,342.066 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="273.189,347.729 265.4,352.262 258.264,348.131 266.041,343.605 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="254.212,349.303 246.459,353.805 239.308,349.689 247.074,345.191 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="320.274,351.879 314.901,355.003 307.743,350.865 313.175,347.729 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="300.298,350.907 292.529,355.41 285.378,351.278 293.171,346.756 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="281.342,352.431 273.558,356.942 266.432,352.819 274.23,348.319 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="308.477,355.595 300.686,360.094 293.579,355.958 301.307,351.451 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="331.172,358.133 325.811,361.306 318.674,357.16 324.019,354.035 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="289.511,357.16 281.709,361.658 274.572,357.542 282.379,353.056 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="270.515,358.725 262.789,363.241 255.615,359.127 263.385,354.61 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="316.606,360.262 308.82,364.797 301.746,360.69 309.477,356.192 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="251.558,360.262 243.816,364.797 163.212,318.275 170.982,313.774 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="297.64,361.87 289.845,366.384 282.729,362.264 290.513,357.73 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="278.693,363.435 270.93,367.984 263.783,363.811 271.533,359.327 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="324.773,365.026 317.039,369.512 309.87,365.416 317.616,360.904 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="259.7,365.026 251.954,369.512 244.818,365.416 252.574,360.904 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="305.785,366.574 298.011,371.076 290.9,366.97 298.688,362.458 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="286.834,368.161 279.073,372.655 271.958,368.554 279.701,364.015 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="267.9,369.726 260.146,374.241 252.963,370.086 260.744,365.588 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="313.988,371.292 306.192,375.811 299.042,371.687 306.867,367.164 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="295.003,372.862 287.257,377.391 280.119,373.275 287.873,368.762 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="276.05,374.436 268.292,378.938 261.156,374.825 268.891,370.301 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="303.169,377.575 295.419,382.086 288.265,377.961 296.04,373.441 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="284.18,379.128 276.464,383.659 269.331,379.531 277.052,375.006 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="292.389,383.845 284.62,388.38 277.48,384.217 285.221,379.723 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="216.065,314.776 208.295,319.271 201.156,315.143 208.896,310.621 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="262.379,354.035 254.607,358.526 247.441,354.406 255.235,349.913 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="153.659,303.744 145.883,308.289 138.735,304.146 146.504,299.644 					" />
          </g>
          <g>
            <polygon fill="#5D5D5D" points="145.485,299.059 137.729,303.561 130.576,299.454 138.334,294.938 					" />
          </g>
        </g>
      </g>
      <g>
        <path fill="#5D5D5D" d="M204.438,351.298c2.465,1.436,2.508,3.783,0.014,5.215l-16.254,9.407
				c-2.477,1.458-6.52,1.458-9.027,0.052l-22.074-12.769c-2.441-1.447-2.471-3.779,0-5.232l16.23-9.438
				c2.439-1.398,6.509-1.398,9.014,0L204.438,351.298z" />
      </g>
      <g>
        <path fill="#FFFFFF" d="M204.438,352.078c2.465,1.445,2.508,3.799,0.014,5.196l-16.254,9.427c-2.477,1.46-6.52,1.46-9.027,0.046
				l-22.074-12.764c-2.441-1.427-2.471-3.779,0-5.246l16.23-9.418c2.439-1.436,6.509-1.436,9.014,0L204.438,352.078z" />
      </g>
    </g>
    <g>
      <polygon fill="#ABBEC5" points="227.783,72.296 230.875,70.508 230.871,73.107 227.778,74.91 		" />
    </g>
    <g>
      <polygon fill="#ABBEC5" points="227.804,65.551 230.893,63.75 230.884,66.358 227.797,68.152 		" />
    </g>
    <g>
      <polygon fill="#ABBEC5" points="227.826,58.458 230.914,56.673 230.905,59.267 227.821,61.066 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="227.778,74.91 230.871,73.107 308.594,117.992 305.508,119.78 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="227.783,72.296 230.875,70.508 308.603,115.38 305.515,117.177 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="305.515,117.177 308.603,115.38 308.594,117.992 305.508,119.78 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="227.797,68.152 230.884,66.358 308.62,111.234 305.53,113.033 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="227.804,65.551 230.893,63.75 308.627,108.631 305.53,110.421 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="305.53,110.421 308.627,108.631 308.62,111.234 305.53,113.033 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="227.821,61.066 230.905,59.267 308.639,104.148 305.545,105.942 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="305.558,103.344 308.639,101.547 308.639,104.148 305.545,105.942 		" />
    </g>
    <g>
      <polygon fill="#3FBAD4" points="305.558,103.344 305.545,105.942 227.821,61.066 227.826,58.458 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="227.826,58.458 230.914,56.673 308.639,101.547 305.558,103.344 		" />
    </g>
    <path fill="#B1D4FC" d="M304.754,96.585c2.861,1.653,5.183,5.65,5.169,8.948l-0.049,17.175c0,0.212-0.043,0.389-0.061,0.59
		l-0.139,21.033l-16.953-25.564l-64.355-37.161c-2.864-1.646-5.174-5.637-5.167-8.938l0.053-17.179
		c0.013-3.292,2.334-4.609,5.196-2.959L304.754,96.585z" />
    <polygon fill="#FFFFFF" points="305.545,105.942 305.558,103.344 227.826,58.458 227.821,61.066 305.545,105.942 	" />
    <polygon fill="#B1D4FC" points="305.508,119.78 305.515,117.177 227.783,72.296 227.778,74.91 305.508,119.78 	" />
    <polygon fill="#B1D4FC" points="305.53,113.033 305.53,110.421 227.804,65.551 227.797,68.152 305.53,113.033 	" />
    <g>
      <polygon fill="#FFFFFF" points="305.515,117.177 305.508,119.78 227.778,74.91 227.783,72.296 		" />
    </g>
    <g>
      <polygon fill="#FFFFFF" points="305.53,110.421 305.53,113.033 227.797,68.152 227.804,65.551 		" />
    </g>
    <path fill="#548EED" d="M313.009,103.503c0-0.087-0.005-0.171-0.016-0.256c0-0.079-0.007-0.164-0.018-0.251
		c-0.005-0.085-0.021-0.17-0.026-0.251c-0.01-0.091-0.028-0.174-0.039-0.262c-0.012-0.085-0.032-0.173-0.045-0.265
		c-0.027-0.085-0.031-0.177-0.054-0.267c-0.018-0.087-0.041-0.182-0.065-0.269c-0.025-0.091-0.047-0.186-0.074-0.273
		c-0.018-0.076-0.04-0.153-0.057-0.219c0-0.003-0.004-0.013-0.009-0.023c-0.004-0.007-0.004-0.022-0.009-0.033
		c-0.027-0.092-0.061-0.182-0.092-0.27c-0.026-0.085-0.057-0.172-0.091-0.26c-0.026-0.085-0.056-0.172-0.093-0.249
		c-0.027-0.087-0.065-0.175-0.104-0.26c-0.022-0.059-0.058-0.129-0.089-0.192c0-0.005-0.001-0.016-0.001-0.024
		c-0.007-0.008-0.011-0.015-0.013-0.028c-0.041-0.087-0.081-0.162-0.114-0.249c-0.046-0.085-0.087-0.179-0.133-0.275
		c-0.054-0.097-0.101-0.199-0.159-0.306c-0.056-0.119-0.122-0.23-0.195-0.344c-0.005-0.013-0.014-0.028-0.018-0.043
		c-0.01-0.02-0.022-0.037-0.03-0.05c-0.128-0.214-0.258-0.424-0.397-0.631c-0.104-0.162-0.215-0.313-0.33-0.464l0,0
		c0-0.011,0-0.015-0.014-0.019c-0.064-0.097-0.144-0.193-0.215-0.287c-0.066-0.083-0.126-0.168-0.2-0.251
		c-0.067-0.07-0.13-0.155-0.191-0.234c-0.069-0.069-0.144-0.142-0.208-0.216c-0.014-0.018-0.027-0.031-0.04-0.046
		c-0.014-0.009-0.019-0.016-0.022-0.026c-0.052-0.053-0.098-0.11-0.148-0.154c-0.071-0.074-0.15-0.146-0.219-0.221
		c-0.078-0.07-0.152-0.137-0.23-0.207c-0.074-0.073-0.16-0.14-0.233-0.204c-0.036-0.03-0.077-0.063-0.117-0.098
		c-0.011-0.009-0.018-0.014-0.031-0.024c-0.025-0.022-0.048-0.046-0.082-0.068c-0.073-0.057-0.147-0.116-0.228-0.172
		c-0.072-0.06-0.15-0.112-0.228-0.164c-0.074-0.049-0.148-0.096-0.218-0.143c-0.075-0.043-0.145-0.092-0.22-0.135
		c-0.018-0.011-0.034-0.024-0.048-0.031l-76.311-44.057c-0.052-0.028-0.107-0.062-0.162-0.092c-0.074-0.035-0.147-0.074-0.22-0.104
		c-0.064-0.033-0.133-0.07-0.202-0.103c-0.076-0.026-0.146-0.061-0.208-0.089c-0.073-0.028-0.14-0.048-0.212-0.077
		c-0.014-0.002-0.022-0.011-0.031-0.011s-0.018,0-0.031-0.011c-0.045-0.009-0.097-0.028-0.145-0.046
		c-0.07-0.018-0.146-0.04-0.219-0.057c-0.068-0.022-0.142-0.036-0.205-0.05c-0.076-0.016-0.152-0.028-0.231-0.035
		c-0.044-0.013-0.101-0.013-0.144-0.018c-0.014,0-0.021,0-0.022,0c-0.028-0.004-0.04-0.007-0.062-0.007
		c-0.087-0.006-0.167-0.013-0.24-0.006c-0.093,0-0.184,0-0.275,0.009c-0.099,0.009-0.192,0.029-0.293,0.046
		c-0.014,0-0.014,0-0.014,0v0.004c-0.122,0.021-0.239,0.057-0.345,0.096c-0.153,0.055-0.291,0.114-0.422,0.186
		c-1.032,0.605-2.063,1.207-3.097,1.801c0.233-0.136,0.498-0.234,0.769-0.291c0.009,0,0.009,0,0.009,0
		c0.271-0.057,0.569-0.07,0.879-0.043c0,0.002,0.01,0.002,0.019,0.002c0.31,0.027,0.624,0.092,0.959,0.21
		c0.007,0.002,0.007,0.002,0.007,0.002c0.346,0.12,0.694,0.284,1.052,0.483l76.306,44.054c0.354,0.204,0.696,0.441,1.024,0.708
		c0.007,0.017,0.026,0.024,0.035,0.035c0.325,0.269,0.647,0.559,0.946,0.879c0.006,0.006,0.006,0.008,0.016,0.017
		c0.31,0.33,0.602,0.677,0.879,1.047c0.266,0.365,0.52,0.765,0.751,1.158c0.009,0.007,0.014,0.021,0.021,0.033
		c0.225,0.392,0.438,0.807,0.622,1.22c0,0,0,0.009,0.005,0.013c0.179,0.415,0.336,0.839,0.463,1.274
		c0,0.002,0.004,0.004,0.004,0.011c0.269,0.863,0.403,1.727,0.403,2.553l-0.049,17.175c0,0.212-0.043,0.389-0.061,0.59
		l-0.139,21.033l3.096-1.794l0.134-21.038c0.006-0.035,0.013-0.072,0.017-0.111c0.009-0.059,0.009-0.116,0.019-0.177
		c0.01-0.046,0.014-0.087,0.014-0.138c0.002-0.026,0.002-0.057,0.002-0.087c0.005-0.02,0.005-0.044,0.005-0.065v-0.007l0.058-17.182
		C313.018,103.654,313.018,103.58,313.009,103.503z" />
    <g>
      <polygon fill="#ABBEC5" points="324.09,67.993 326.9,66.356 326.9,68.732 324.077,70.367 		" />
    </g>
    <g>
      <polygon fill="#ABBEC5" points="324.103,61.835 326.918,60.199 326.914,62.575 324.099,64.214 		" />
    </g>
    <g>
      <polygon fill="#ABBEC5" points="324.125,55.379 326.94,53.745 326.928,56.111 324.12,57.755 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="324.077,70.367 326.9,68.732 397.77,109.649 394.959,111.291 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="324.09,67.993 326.9,66.356 397.781,107.278 394.959,108.911 		" />
    </g>
    <g>
      <polygon fill="#3FBAD4" points="394.959,108.911 394.959,111.291 324.077,70.367 324.09,67.993 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="394.959,108.911 397.781,107.278 397.77,109.649 394.959,111.291 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="324.099,64.214 326.914,62.575 397.788,103.497 394.972,105.131 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="324.103,61.835 326.918,60.199 397.795,101.121 394.979,102.753 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="394.979,102.753 397.795,101.121 397.788,103.497 394.972,105.131 		" />
    </g>
    <g>
      <polygon fill="#BFD4DC" points="324.12,57.755 326.928,56.111 397.809,97.038 394.989,98.673 		" />
    </g>
    <g>
      <polygon fill="#36A0B7" points="324.125,55.379 326.94,53.745 397.809,94.66 394.995,96.295 		" />
    </g>
    <g>
      <polygon fill="#3190A4" points="394.995,96.295 397.809,94.66 397.809,97.038 394.989,98.673 		" />
    </g>
    <path fill="#B1D4FC" d="M394.461,90.252c2.601,1.505,4.714,5.147,4.712,8.148l-0.046,15.667c-0.016,2.997-2.136,4.199-4.741,2.697
		l-58.673-33.877l-15.547,5.405l-0.011-19.248c-0.013-0.206-0.047-0.397-0.047-0.597l0.047-15.667c0-3.001,2.129-4.203,4.741-2.695
		L394.461,90.252z" />
    <polygon fill="#B1D4FC" points="394.972,105.131 394.979,102.753 324.103,61.835 324.099,64.214 394.972,105.131 	" />
    <polygon fill="#FFFFFF" points="394.959,111.291 394.959,108.911 324.09,67.993 324.077,70.367 394.959,111.291 	" />
    <polygon fill="#B1D4FC" points="394.989,98.673 394.995,96.295 324.125,55.379 324.12,57.755 394.989,98.673 	" />
    <g>
      <polygon fill="#FFFFFF" points="394.979,102.753 394.972,105.131 324.099,64.214 324.103,61.835 		" />
    </g>
    <g>
      <polygon fill="#FFFFFF" points="394.995,96.295 394.989,98.673 324.12,57.755 324.125,55.379 		" />
    </g>
    <path fill="#548EED" d="M401.979,96.546c-0.005-0.067-0.005-0.146-0.005-0.223c-0.011-0.079-0.02-0.151-0.023-0.232
		c-0.007-0.077-0.018-0.151-0.027-0.229c-0.011-0.081-0.018-0.156-0.033-0.234c-0.013-0.083-0.029-0.164-0.043-0.245
		c-0.014-0.079-0.029-0.159-0.047-0.247c-0.021-0.081-0.036-0.164-0.063-0.245c-0.018-0.085-0.039-0.166-0.061-0.251
		c-0.02-0.065-0.039-0.131-0.057-0.192c0-0.01,0-0.017,0-0.021c-0.01-0.004-0.01-0.022-0.018-0.033
		c-0.022-0.083-0.054-0.162-0.079-0.245s-0.052-0.16-0.079-0.24c-0.035-0.079-0.057-0.155-0.092-0.229
		c-0.034-0.077-0.063-0.155-0.092-0.229c-0.026-0.062-0.047-0.117-0.078-0.178c-0.008-0.006-0.008-0.006-0.008-0.018
		c-0.005-0.008-0.005-0.02-0.012-0.03c-0.033-0.077-0.068-0.151-0.1-0.229c-0.043-0.083-0.077-0.168-0.119-0.249
		c-0.051-0.097-0.098-0.184-0.155-0.277c-0.049-0.107-0.101-0.21-0.161-0.304c-0.014-0.02-0.022-0.04-0.027-0.059
		c-0.018-0.02-0.026-0.035-0.039-0.057c-0.113-0.194-0.227-0.376-0.354-0.564c-0.095-0.14-0.192-0.286-0.296-0.428
		c-0.066-0.085-0.14-0.175-0.207-0.269c-0.062-0.075-0.119-0.149-0.188-0.225c-0.058-0.072-0.114-0.144-0.184-0.214
		c-0.056-0.07-0.11-0.136-0.175-0.202c-0.014-0.013-0.029-0.02-0.033-0.04c-0.016-0.003-0.021-0.013-0.038-0.021
		c-0.034-0.048-0.077-0.094-0.129-0.14c-0.067-0.067-0.128-0.133-0.194-0.199c-0.064-0.063-0.136-0.129-0.207-0.194
		c-0.074-0.059-0.146-0.12-0.212-0.182c-0.038-0.021-0.071-0.054-0.105-0.079c-0.009-0.015-0.017-0.021-0.03-0.028
		c-0.026-0.022-0.048-0.046-0.074-0.061c-0.066-0.057-0.141-0.109-0.208-0.164c-0.063-0.053-0.134-0.094-0.202-0.143
		c-0.071-0.048-0.137-0.092-0.201-0.133c-0.071-0.04-0.131-0.083-0.197-0.123c-0.016-0.009-0.036-0.015-0.051-0.026l-69.568-40.16
		c-0.052-0.033-0.1-0.059-0.148-0.09c-0.061-0.035-0.133-0.062-0.195-0.101c-0.066-0.031-0.133-0.061-0.189-0.089
		c-0.064-0.029-0.136-0.053-0.195-0.083c-0.063-0.024-0.125-0.044-0.187-0.072c-0.015,0-0.02,0-0.042-0.006
		c0,0-0.006-0.004-0.009-0.007c-0.048-0.013-0.091-0.035-0.138-0.043c-0.067-0.022-0.133-0.04-0.194-0.062
		c-0.065-0.011-0.126-0.026-0.192-0.037c-0.073-0.013-0.148-0.026-0.21-0.037c-0.052,0-0.092-0.004-0.144-0.011
		c-0.005,0-0.012,0-0.018,0c-0.018,0-0.03-0.011-0.053-0.011c-0.07-0.006-0.148-0.006-0.219-0.006c-0.082,0-0.169,0-0.239,0.017
		c-0.103,0.011-0.195,0.018-0.28,0.04h-0.009h-0.005c-0.103,0.02-0.214,0.046-0.316,0.085c-0.125,0.053-0.261,0.107-0.38,0.182
		c-0.842,0.485-1.69,0.975-2.539,1.473c-0.082,0.046-0.196,0.114-0.283,0.158c0.215-0.125,0.456-0.208,0.704-0.258
		c0.002-0.01,0.002-0.01,0.005-0.01c0.252-0.048,0.521-0.057,0.804-0.036c0.004,0,0.004,0,0.014,0.006
		c0.283,0.024,0.571,0.083,0.877,0.187c0.004,0,0.011,0.006,0.014,0.006c0.31,0.105,0.632,0.25,0.955,0.437l69.564,40.167
		c0.328,0.188,0.639,0.402,0.943,0.653c0.005,0.006,0.005,0.006,0.019,0.011c0.301,0.249,0.589,0.518,0.864,0.817
		c0.007,0.002,0.01,0.004,0.01,0.01c0.563,0.593,1.06,1.277,1.484,2.004c0.014,0.013,0.014,0.029,0.027,0.042
		c0.206,0.358,0.398,0.73,0.563,1.106l0,0c0.168,0.387,0.311,0.783,0.426,1.174c0,0.005,0,0.005,0.004,0.009
		c0.241,0.786,0.372,1.573,0.372,2.321l-0.046,15.667c0,0.365-0.038,0.702-0.107,1.014c0,0.016,0,0.022,0,0.028
		c-0.063,0.307-0.156,0.588-0.262,0.833c0,0.007-0.005,0.011-0.005,0.018c-0.103,0.206-0.229,0.378-0.354,0.542
		c-0.052,0.062-0.09,0.116-0.144,0.166c-0.149,0.164-0.319,0.313-0.51,0.426c0.937-0.548,1.882-1.092,2.81-1.637
		c0.197-0.113,0.368-0.265,0.523-0.424c0.042-0.05,0.086-0.105,0.127-0.162c0.062-0.072,0.128-0.137,0.174-0.216
		c0.071-0.099,0.125-0.206,0.171-0.323c0.009,0,0.009-0.007,0.009-0.018c0.007,0,0.007,0,0.009-0.007
		c0.036-0.081,0.071-0.153,0.105-0.24c0.026-0.081,0.057-0.164,0.079-0.251c0.021-0.077,0.039-0.157,0.066-0.241
		c0.009-0.028,0.016-0.065,0.016-0.096c0.005-0.019,0.01-0.024,0.01-0.039c0.004-0.031,0.013-0.062,0.018-0.094
		c0.01-0.079,0.025-0.153,0.029-0.221c0.016-0.079,0.022-0.155,0.029-0.233c0.004-0.073,0.014-0.143,0.014-0.223
		c0-0.074,0.002-0.148,0.002-0.227c0-0.004,0-0.009,0-0.009l0.044-15.667C401.979,96.692,401.979,96.623,401.979,96.546z" />
    <g>
      <g enableBackground="new    ">
        <g>
          <polygon fill="#CACACA" points="272.211,188.88 279.443,184.679 227.5,116.165 220.27,120.368 				" />
        </g>
        <g>
          <polygon fill="#B8B8B8" points="324.369,180.465 331.6,176.263 279.443,184.679 272.211,188.88 				" />
        </g>
        <g>
          <polygon fill="#96C4FF" points="324.145,257.397 331.378,253.194 331.6,176.263 324.369,180.465 				" />
        </g>
        <g>
          <g>
            <polygon fill="#2C6AB5" points="220.27,120.368 272.211,188.88 324.369,180.465 					" />
          </g>
        </g>
        <g>
          <polygon fill="#EEFAFE" points="324.369,180.465 331.6,176.263 227.5,116.165 220.27,120.368 				" />
        </g>
        <g>
          <polygon fill="#FFFFFF" points="220.27,120.368 220.053,197.3 324.145,257.397 324.369,180.465 272.211,188.88 				" />
        </g>
      </g>
    </g>
    <g>
      <g enableBackground="new    ">
        <g>
          <polygon fill="#CACACA" points="314.203,295.529 319.01,292.737 284.477,247.184 279.673,249.977 				" />
        </g>
        <g>
          <polygon fill="#B8B8B8" points="348.883,289.937 353.688,287.142 319.01,292.737 314.203,295.529 				" />
        </g>
        <g>
          <polygon fill="#96C4FF" points="348.736,341.087 353.54,338.291 353.688,287.142 348.883,289.937 				" />
        </g>
        <g>
          <g>
            <polygon fill="#2C6AB5" points="279.673,249.977 314.203,295.529 348.883,289.937 					" />
          </g>
        </g>
        <g>
          <polygon fill="#EEFAFE" points="348.883,289.937 353.688,287.142 284.477,247.184 279.673,249.977 				" />
        </g>
        <g>
          <polygon fill="#FFFFFF" points="279.673,249.977 279.524,301.128 348.736,341.087 348.883,289.937 314.203,295.529 				" />
        </g>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path fill="#CADFE8" d="M170.877,162.722c0,0,2.858-7.814,14.077-4.17c11.217,3.652,21.962,8.793,21.962,8.793l23.703-13.257
					c0,0,4.633,2.359,4.619,5.91c0,0-18.95,18.714-29.224,19.764C206.015,179.762,162.237,179.222,170.877,162.722z" />
        </g>
        <g>
          <path fill="#EFB185" d="M231.521,154.628c0,0,2.505-5.492,4.66-7.097c2.162-1.604,4.028-2.123,4.761-2.184
					c0.739-0.057-1.42,2.232-2.148,2.949c-0.72,0.716-1.126,3.184,4.583,0.512c5.709-2.689,4.639-1.48,4.551-0.958
					c-0.087,0.533-5.898,9.481-12.747,11.477C235.181,159.328,234.704,156.93,231.521,154.628z" />
        </g>
        <g>
          <path fill="#AFCED8" d="M206.015,179.762c10.273-1.05,29.224-19.764,29.224-19.764c0-0.983-0.372-1.873-0.891-2.655
					c-4.251,3.373-16.71,13.138-24.341,17.951c-9.319,5.867-40.12-9.169-40.179-9.466
					C167.928,179.285,206.015,179.762,206.015,179.762z" />
        </g>
      </g>
      <g>
        <path fill="#111114" d="M189.547,326.148c0,0,4.731,0.701,3.586,3.434c-1.145,2.719-6.595,6.86-7.296,8.589
				c-0.726,1.713-5.153,7.86-6.578,7.292c-1.443-0.577-5.159,0.28-4.73-6.726C174.961,331.726,183.253,325.859,189.547,326.148z" />
      </g>
      <g>
        <path fill="#111114" d="M198.563,351.899c0,0-2.291,6.738,3.436,8.301c5.713,1.569,4.004-2.007,16.454,1.132
				c12.453,3.152,13.878-3.276,13.591-4.712c-0.285-1.44-8.59-4.008-11.303-4.721c-2.721-0.702-7.866-2.006-7.866-2.006h-14.76
				L198.563,351.899z" />
      </g>
      <g>
        <path fill="#2B454E" d="M175.049,233.811c0,0-0.081,40.624-0.081,45.196c0,4.575-5.108,40.63-2.036,56.369
				c0,0,8.857,8.878,16.683,0c0,0,2.513-45.125,4.781-55.315c2.257-10.186,1.497-41.325,1.497-41.325L175.049,233.811z" />
      </g>
      <g>
        <path fill="#2B454E" d="M190.589,239.582c0,0,2.008,26.23,4.094,52.244c0,0,1.3,51.624,2.533,59.06
				c0,0,16.642,4.131,18.302-3.718c0,0-0.405-45.429,0-54.915c0.43-9.508,2.689-39.264-1.057-54.546
				C210.715,222.431,190.589,239.582,190.589,239.582z" />
      </g>
      <g>
        <path fill="#2B373A" d="M197.216,350.885c-1.233-7.436-2.533-59.06-2.533-59.06c-1.396-17.509-2.991-38.026-3.744-47.661
				l11.3,2.19c0,0,6.623,22.713,5.502,51.888c-0.877,22.589-1.253,44.815-1.369,53.718
				C201.783,352.011,197.216,350.885,197.216,350.885z" />
      </g>
      <g>
        <path fill="#2B373A" d="M172.932,335.375c-3.072-15.739,2.036-51.794,2.036-56.369c0-4.36,0.067-41.395,0.074-44.896l8.942,6.872
				c0,0,2.836,29.042,0,45.583c-2.588,15.055-0.921,44.356-2.183,52.753C176.866,339.318,172.932,335.375,172.932,335.375z" />
      </g>
      <g>
        <path fill="#CADFE8" d="M172.563,160.047c0,0-8.587,4.292-3.72,18.882c4.858,14.596,8.292,24.038,7.998,29.469
				c-0.275,5.447-1.652,25.568-2.798,29.288c0,0-0.655,6.251,22.674,9.397c0,0,21.039,2.454,17.324-12.421
				c-3.725-14.884-0.854-31.116-0.293-36.84c0.584-5.733,4.023-17.175-10.299-24.899
				C189.144,165.196,181.711,159.472,172.563,160.047z" />
      </g>
      <g>
        <g>
          <path fill="#EFB185" d="M182.721,152.836c0,0,0.904,7.624-1.709,12.397c-2.379,4.328,15.588,8.279,16.016,4.271
					c0.045-0.42-3.409-6.999,0.925-14.782C200.229,150.655,182.721,152.836,182.721,152.836z" />
        </g>
        <g>
          <path fill="#DD9569" d="M181.101,165.496c1.947-3.55,1.947-8.666,1.813-11.064l13.113,6.511c0,0.016-0.005,0.02-0.005,0.031
					c-0.479,0.109-9.34,2.365-11.256,8.732C182.073,168.604,180.242,167.074,181.101,165.496z" />
        </g>
        <g>
          <path fill="#EFB185" d="M201.631,132.584c0,0,2.802,6.917,2.238,12.882c-0.602,6.41,0.362,7.713-0.043,10.568
					c-0.171,1.222-0.269,3.066-1.455,4.203c-1.98,1.913-6.059,2.621-11.603,0.602c-6.687-2.457-13.736-11.654-10.872-19.916
					C182.768,132.647,191.572,125.841,201.631,132.584z" />
        </g>
        <g>
          <path fill="#E6A376" d="M180.193,149.859c2.699-7.446,9.338-16.368,9.28-16.836c-0.03-0.293,2.177-1.683,4.691-3.158
					c2.347,0.145,4.865,0.977,7.466,2.719c0,0,0.759,1.849,1.393,4.452c-1.595,1.792-4.838,3.825-5.322,5.333
					c-1.156,3.558-0.51,8.968,0.556,11.339c1.062,2.392-3.231,5.888-2.328,7.993c0.042,0.11,0.042,0.204,0.018,0.295
					c-1.528-0.109-3.261-0.468-5.178-1.158C186.502,159.269,182.082,154.933,180.193,149.859z" />
        </g>
        <g>
          <path fill="#221101" d="M203.611,129.749c0,0,0.973,5.925-4.874,8.793c-3.524,1.718-3.196,7.381-3.037,8.423
					c0.169,1.056,1.203,5.83,0.771,6.45c-0.824,1.178-3.565-2.512-4.873-2.256c-3.33,0.656-2.023,3.749,0.991,7.001
					c3.219,3.458-5.245,4.874-9.157,2.024c-2.08-1.513-2.837-2.426-5.565-7.86c-3.975-7.91-4.176-17.418,5.181-22.486
					C192.407,124.77,197.807,132.04,203.611,129.749z" />
        </g>
        <g>
          <path fill="#0F0F0F" d="M183.433,160.185c-2.08-1.513-2.837-2.426-5.565-7.86c-3.975-7.91-4.176-17.418,5.181-22.486
					c9.359-5.069,14.759,2.201,20.563-0.09c0,0,0.17,1-0.047,2.376c-2.963,0.48-11.072,2.081-13.167,5.578
					c-2.575,4.282-5.437,2.903-5.216,8.24c0.2,5.145-1.13,11.875,7.513,14.786C189.474,163.035,184.954,161.294,183.433,160.185z" />
        </g>
      </g>
      <g>
        <path fill="#DEF0F9" d="M196.014,167.126l5.852,4.929c0,0-18.214,5.21-23.554-3.711c-0.759-1.27,0.16-5.495,3.338-6.286
				C181.649,162.058,182.265,169.32,196.014,167.126z" />
      </g>
      <g>
        <path fill="#AFCED8" d="M214.041,234.662c-3.725-14.884-0.854-31.116-0.293-36.84c0.584-5.674,3.921-16.941-9.901-24.665
				c-4.804,2.924-11.767,9.219-7.82,19.844c4.884,13.164,6.493,41.696,7.449,54.041
				C209.259,246.386,216.321,243.797,214.041,234.662z" />
      </g>
      <g>
        <g>
          <path fill="#CADFE8" d="M195.982,181.469c0,0-0.594-7.486,9.989-8.71c10.583-1.219,21.36-1.053,21.36-1.053l14.468-19.817
					c0,0,4.688,0.214,6.015,3.139c0,0-8.512,22.49-16.555,27.213C231.26,182.24,195.1,198.274,195.982,181.469z" />
        </g>
        <g>
          <path fill="#EFB185" d="M242.743,151.997c0,0-0.004-5.458,1.163-7.582c1.163-2.129,2.514-3.252,3.093-3.578
					c0.579-0.333-0.346,2.367-0.653,3.228c-0.328,0.85,0.26,3.028,3.956-1.32c3.677-4.345,3.247-2.951,3.369-2.485
					c0.132,0.47-1.28,10.015-6.141,14.235C247.53,154.495,246.226,152.692,242.743,151.997z" />
        </g>
        <g>
          <path fill="#AFCED8" d="M231.26,182.24c8.043-4.723,16.555-27.213,16.555-27.213c-0.363-0.822-1.008-1.406-1.729-1.856
					c-2.215,4.371-8.768,17.068-13.234,23.909c-5.455,8.314-36.392,7.562-36.553,7.342C199.803,196.191,231.26,182.24,231.26,182.24
					z" />
        </g>
      </g>
    </g>
    <g id="Plants_2_">
      <g id="XMLID_90_">
        <path id="XMLID_93_" fill="#548EED" d="M413.701,327.188l-9.525-5.5c0,0-2.334-20.402-0.781-36.202
				c2.182-22.203,16.52-48.917,33.626-42.644c17.104,6.274-7.844,28.885-13.396,47.717
				C418.07,309.39,413.701,327.188,413.701,327.188z" />
        <path id="XMLID_92_" opacity="0.15" d="M413.701,327.188l-9.525-5.5c0,0-2.334-20.402-0.781-36.202
				c2.182-22.203,16.52-48.917,33.626-42.644c17.104,6.274-7.844,28.885-13.396,47.717
				C418.07,309.39,413.701,327.188,413.701,327.188z" />
        <path id="XMLID_91_" fill="#FFFFFF" d="M408.691,324.872c0.007,0,0.013,0,0.02,0c0.318-0.011,0.568-0.278,0.558-0.597
				c-1.674-49.333,22.885-74.149,23.132-74.394c0.228-0.224,0.23-0.589,0.007-0.815c-0.227-0.227-0.592-0.229-0.815-0.006
				c-0.253,0.249-25.166,25.421-23.477,75.254C408.125,324.627,408.382,324.872,408.691,324.872z" />
      </g>
      <g id="XMLID_49_">
        <path id="XMLID_88_" fill="#548EED" d="M419.691,330.617l-6.779-3.914c0,0-1.152-14,5.711-31.007
				c5.976-14.806,20.803-27.089,28.26-24.508c7.457,2.581,7.271,7.869,3.636,13.416S424.764,303.372,419.691,330.617z" />
        <path id="XMLID_87_" fill="#FFFFFF" d="M416.115,329.129h0.003c0.318-0.001,0.575-0.26,0.574-0.58
				c-0.135-31.192,29.153-52.273,29.45-52.483c0.26-0.184,0.321-0.543,0.137-0.804c-0.182-0.259-0.541-0.323-0.804-0.137
				c-0.3,0.212-30.074,21.644-29.937,53.43C415.541,328.873,415.798,329.129,416.115,329.129z" />
      </g>
    </g>
    <g id="Plants_4_">
      <g id="XMLID_103_">
        <g id="XMLID_108_">
          <path id="XMLID_109_" fill="#548EED" d="M82.508,286.721l8.796-5.936c0,0,3.872-23.046,0.274-43.289
					c-5.058-28.46-24.687-37.932-31.69-34.958s-8.5,11.463,4.567,27.711C72.165,239.836,82.237,260.223,82.508,286.721z" />
        </g>
        <g id="XMLID_106_" opacity="0.15">
          <path id="XMLID_107_" d="M82.508,286.721l8.796-5.936c0,0,3.872-23.046,0.274-43.289c-5.058-28.46-24.687-37.932-31.69-34.958
					s-8.5,11.463,4.567,27.711C72.165,239.836,82.237,260.223,82.508,286.721z" />
        </g>
        <g id="XMLID_104_">
          <path id="XMLID_105_" fill="#FFFFFF" d="M85.528,275.321c-0.029,0-0.059-0.001-0.088-0.005c-0.35-0.042-0.6-0.358-0.559-0.708
					c3.178-26.798-8.136-55.872-20.439-67.44c-0.257-0.24-0.27-0.645-0.028-0.9c0.242-0.254,0.646-0.269,0.901-0.027
					c12.528,11.778,24.057,41.325,20.832,68.518C86.11,275.078,85.841,275.315,85.528,275.321z" />
        </g>
      </g>
      <g id="XMLID_95_">
        <g id="XMLID_100_">
          <path id="XMLID_101_" fill="#548EED" d="M59.468,302.263c-1.396,0.838-5.477-2.189-6.606-2.916
					c-1.934-1.244-3.515-3.027-4.581-5.058c-1.242-2.364-1.592-4.916-1.269-7.563c0.167-1.373,0.524-2.715,0.881-4.051
					c0.351-1.313,0.7-2.625,1.05-3.937c0.346-1.295,0.693-2.634,0.498-3.959c-0.395-2.671-2.807-4.5-4.999-6.077
					c-2.424-1.743-4.958-4.056-5.006-7.28c-0.038-2.487,1.206-4.822,2.709-6.804c1.504-1.981,3.3-3.741,4.652-5.829
					c0.975-1.503,1.724-3.26,1.526-5.04c-0.123-1.105-0.603-2.141-1.152-3.108c-1.179-2.077-2.717-3.97-3.545-6.209
					c-0.829-2.239-0.771-5.046,0.957-6.693c1.419-1.352,3.562-1.578,5.52-1.487c1.811,0.084,3.594,0.867,5.193,1.716
					c5.172,2.746,8.21,8.75,13.682,10.835c2.111,0.804,4.409,0.945,6.604,1.479c2.195,0.535,4.442,1.607,5.492,3.607
					c2.07,3.942-1.534,8.921-0.051,13.12c1.312,3.716,4.974,5.077,7.08,8.408c0.92,1.457,1.159,3.157,1.049,4.877
					c-0.188,2.912-0.572,5.053-1.904,8.313c-1.102,2.694-4.371,7.869-4.371,7.869S59.681,302.134,59.468,302.263z" />
        </g>
        <g id="XMLID_98_">
          <path id="XMLID_99_" fill="#FFFFFF" d="M72.985,293.971c-0.008,0-0.018,0-0.018,0c-0.36-0.008-0.639-0.3-0.632-0.651
					c0.563-25.317-11.895-53.015-20.719-60.961c-0.261-0.235-0.282-0.638-0.048-0.9c0.236-0.261,0.639-0.281,0.9-0.047
					c9.002,8.106,21.713,36.263,21.142,61.937C73.603,293.692,73.325,293.965,72.985,293.971z" />
        </g>
        <g id="XMLID_96_">
          <path id="XMLID_97_" fill="#FFFFFF" d="M68.321,263.129c-0.063,0.001-0.126-0.006-0.188-0.025
					c-8.176-2.356-18.313-2.603-18.415-2.605c-0.353-0.008-0.631-0.3-0.624-0.651c0.009-0.351,0.27-0.634,0.651-0.624
					c0.104,0.003,10.402,0.253,18.74,2.656c0.338,0.097,0.533,0.45,0.436,0.789C68.842,262.944,68.594,263.124,68.321,263.129z" />
        </g>
      </g>
    </g>
  </g>
  <g id="Speech_Bubble">
  </g>
  <g id="Question_Marks_1_">
  </g>
  <g id="Character">
  </g>
</svg>
)
}