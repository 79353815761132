import React from 'react'

export default function invalidUrl() {
	return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500" xmlSpace="preserve">
        <g id="Floor">
        </g>
        <g id="Shadow">
        </g>
        <g id="Plants_1_">
        </g>
        <g id="Speech_Bubble">
          <g id="Plants_5_">
            <g id="XMLID_183_">
              <path id="XMLID_186_" fill="#548EED" d="M89.363,271.781l-9.525-5.5c0,0-2.334-20.402-0.781-36.202
              c2.182-22.203,16.52-48.917,33.626-42.644c17.104,6.274-7.844,28.885-13.396,47.717
              C93.732,253.984,89.363,271.781,89.363,271.781z" />
              <path id="XMLID_185_" opacity="0.15" d="M89.363,271.781l-9.525-5.5c0,0-2.334-20.402-0.781-36.202
              c2.182-22.203,16.52-48.917,33.626-42.644c17.104,6.274-7.844,28.885-13.396,47.717
              C93.732,253.984,89.363,271.781,89.363,271.781z" />
              <path id="XMLID_184_" fill="#FFFFFF" d="M84.354,269.466c0.007,0,0.013,0,0.02,0c0.318-0.011,0.568-0.278,0.558-0.597
              c-1.674-49.333,22.885-74.149,23.132-74.394c0.228-0.224,0.23-0.589,0.007-0.815c-0.227-0.227-0.592-0.229-0.815-0.006
              c-0.253,0.249-25.166,25.421-23.477,75.254C83.787,269.221,84.044,269.466,84.354,269.466z" />
            </g>
            <g id="XMLID_180_">
              <path id="XMLID_182_" fill="#548EED" d="M95.354,275.211l-6.779-3.914c0,0-1.152-14,5.711-31.007
              c5.976-14.806,20.803-27.089,28.26-24.508c7.457,2.581,7.271,7.869,3.636,13.416S100.426,247.966,95.354,275.211z" />
              <path id="XMLID_181_" fill="#FFFFFF" d="M91.777,273.723h0.003c0.318-0.001,0.575-0.26,0.574-0.58
              c-0.135-31.192,29.153-52.273,29.45-52.483c0.26-0.184,0.321-0.543,0.137-0.804c-0.182-0.259-0.541-0.323-0.804-0.137
              c-0.3,0.212-30.074,21.644-29.937,53.43C91.203,273.466,91.46,273.723,91.777,273.723z" />
            </g>
          </g>
          <g>
            <defs>
              <rect id="SVGID_1_" x="6.714" y={-3} width={500} height={500} />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <path clipPath="url(#SVGID_2_)" fill="#EFEFEF" d="M88.535,443.215c-92.882-53.626-92.882-140.569,0-194.195
            c92.883-53.625,243.475-53.625,336.357,0c92.882,53.626,92.882,140.569,0,194.195C332.01,496.84,181.418,496.84,88.535,443.215" />
          </g>
          <g>
            <defs>
              <rect id="SVGID_3_" x="6.714" y={-3} width={500} height={500} />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlinkHref="#SVGID_3_" overflow="visible" />
            </clipPath>
            <path clipPath="url(#SVGID_4_)" fill="#E0E0E0" d="M214.387,453.335L72.583,371.408c-5.532-3.194-5.532-8.421,0-11.615
            l229.604-132.658c2.277-1.315,6.005-1.315,8.283,0l145.283,83.944c2.922,1.688,2.921,5.905-0.001,7.593L222.67,453.335
            C220.392,454.65,216.665,454.65,214.387,453.335" />
            <path clipPath="url(#SVGID_4_)" fill="#E0E0E0" d="M428.166,390.181c20.524,11.85,20.524,31.063,0,42.912s-53.801,11.85-74.325,0
            s-20.524-31.063,0-42.912S407.642,378.331,428.166,390.181" />
          </g>
          <path fill="#548EED" d="M87.222,323.806c0,0-0.299-21.347-9.17-38.774c-8.869-17.428-22.279-29.089-35.635-31.492
          c-13.355-2.404-25.46,8.898-11.902,18.837c13.557,9.938,38.283,21.165,46.893,54.146L87.222,323.806z" />
          <g opacity="0.15">
            <g>
              <defs>
                <rect id="SVGID_5_" x="24.577" y="253.211" width="62.645" height="73.313" />
              </defs>
              <clipPath id="SVGID_6_">
                <use xlinkHref="#SVGID_5_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_6_)" fill="#548EED" d="M87.222,323.806c0,0-0.299-21.347-9.17-38.774
              c-8.869-17.428-22.279-29.089-35.635-31.492c-13.355-2.404-25.46,8.898-11.902,18.837c13.557,9.938,38.283,21.165,46.893,54.146
              L87.222,323.806z" />
            </g>
          </g>
          <path fill="#FFFFFF" d="M83.819,317.931c0.049-0.004,0.099-0.012,0.146-0.027c0.385-0.112,0.604-0.515,0.489-0.896
          c-10.724-36.4-36.313-53.7-48.347-57.762c-0.377-0.131-0.787,0.076-0.916,0.453c-0.127,0.38,0.074,0.788,0.453,0.916
          c11.787,3.979,36.868,20.973,47.424,56.801C83.168,317.75,83.487,317.958,83.819,317.931" />
          <path fill="#548EED" d="M88.575,343.95c-4.957,2.852-7.801,4.696-16.896,5.462c-5.425,0.458-12.767-0.312-19.099-2.593
          c-1.687-0.608-4.295-1.939-2.828-3.563c0.758-0.839,9.677-2.255,12.867-6.008c-3.733,0.998-8.645,2.133-12.51,2.217
          c-3.865,0.085-7.886-0.842-10.851-3.32c-2.944-2.462-4.536-6.139-6.026-9.675c-0.51-1.206-0.992-2.687-0.212-3.738
          c0.497-0.668,1.359-0.932,2.161-1.155c3.379-0.94,6.757-1.881,10.137-2.821c2.204-0.47,0.648-1.928-0.186-2.051
          c-1.448-0.215-2.832-0.21-3.676-0.165c-3.869,0.207-8.144,0.937-11.303-1.309c-1.891-1.345-3.006-3.569-3.479-5.84
          c-0.167-0.8-0.833-11.243-0.374-11.369c2.515-0.686,5.149-1.05,7.733-1.346c2.451-0.28,4.838-0.395,7.281-0.725
          c2.756-0.372,5.538-0.555,8.32-0.545c0.943,0.003,1.941,0.043,2.738,0.547c1.098,0.692,1.533,2.075,1.674,3.364
          c0.139,1.29,0.072,2.626,0.521,3.842c1.187-0.103,1.574-1.646,1.545-2.837c-0.031-1.192-0.008-2.679,1.066-3.193
          c0.546-0.261,1.188-0.155,1.781-0.034c3.777,0.773,7.461,1.999,10.947,3.642c1.597,0.751,3.189,1.62,4.338,2.959
          c1.695,1.976,2.195,4.717,2.209,7.32c0.016,2.604-0.387,5.206-0.217,7.803c2.037-1.819,4.869-7.351,5.092-10.072
          c4.381,2.816,6.977,8.621,7.793,16.559c0.537,5.234,0.406,12.134-0.321,17.442C88.778,342.93,88.737,343.856,88.575,343.95" />
          <g opacity="0.3">
            <g>
              <defs>
                <rect id="SVGID_7_" x="26.144" y="295.405" width="63.311" height="54.134" />
              </defs>
              <clipPath id="SVGID_8_">
                <use xlinkHref="#SVGID_7_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_8_)" fill="#548EED" d="M88.575,343.95c-4.957,2.852-7.801,4.696-16.896,5.462
              c-5.425,0.458-12.767-0.312-19.099-2.593c-1.687-0.608-4.295-1.939-2.828-3.563c0.758-0.839,9.677-2.255,12.867-6.008
              c-3.733,0.998-8.645,2.133-12.51,2.217c-3.865,0.085-7.886-0.842-10.851-3.32c-2.944-2.462-4.536-6.139-6.026-9.675
              c-0.51-1.206-0.992-2.687-0.212-3.738c0.497-0.668,1.359-0.932,2.161-1.155c3.379-0.94,6.757-1.881,10.137-2.821
              c2.204-0.47,0.648-1.928-0.186-2.051c-1.448-0.215-2.832-0.21-3.676-0.165c-3.869,0.207-8.144,0.937-11.303-1.309
              c-1.891-1.345-3.006-3.569-3.479-5.84c-0.167-0.8-0.833-11.243-0.374-11.369c2.515-0.686,5.149-1.05,7.733-1.346
              c2.451-0.28,4.838-0.395,7.281-0.725c2.756-0.372,5.538-0.555,8.32-0.545c0.943,0.003,1.941,0.043,2.738,0.547
              c1.098,0.692,1.533,2.075,1.674,3.364c0.139,1.29,0.072,2.626,0.521,3.842c1.187-0.103,1.574-1.646,1.545-2.837
              c-0.031-1.192-0.008-2.679,1.066-3.193c0.546-0.261,1.188-0.155,1.781-0.034c3.777,0.773,7.461,1.999,10.947,3.642
              c1.597,0.751,3.189,1.62,4.338,2.959c1.695,1.976,2.195,4.717,2.209,7.32c0.016,2.604-0.387,5.206-0.217,7.803
              c2.037-1.819,4.869-7.351,5.092-10.072c4.381,2.816,6.977,8.621,7.793,16.559c0.537,5.234,0.406,12.134-0.321,17.442
              C88.778,342.93,88.737,343.856,88.575,343.95" />
            </g>
          </g>
          <path fill="#FFFFFF" d="M87.757,342.963c-0.217,0-0.426-0.118-0.533-0.323c-14.186-27.241-45.571-38.721-53.198-40.304
          c-0.325-0.067-0.534-0.386-0.466-0.71s0.391-0.525,0.71-0.466c7.745,1.607,39.614,13.265,54.019,40.925
          c0.153,0.294,0.038,0.656-0.256,0.81C87.943,342.941,87.85,342.963,87.757,342.963" />
          <path fill="#FFFFFF" d="M46.532,327.266c-0.311,0-0.572-0.238-0.599-0.553c-0.024-0.33,0.222-0.619,0.552-0.646
          c15.657-1.229,20.721-7.359,20.771-7.422c0.205-0.26,0.582-0.301,0.841-0.097c0.259,0.206,0.304,0.581,0.101,0.841
          c-0.213,0.269-5.371,6.598-21.619,7.875C46.563,327.266,46.548,327.266,46.532,327.266" />
          <path fill="#548EED" d="M466.749,251.298c-6.939,7.59-19.67,20.079-27.07,32.14c-0.02,0.03-0.04,0.06-0.05,0.09
          c-2.89,4.73-4.96,9.39-5.53,13.66l-0.52,7.71l-3.17-1.83l-0.53-0.31l-1.31-0.75c-1.877-1.088-3.056-3.075-3.114-5.244
          c-0.509-18.801,4.515-34.782,9.374-44.455c6.65-13.21,19.11-26.682,29.941-25.361C474.839,228.178,476.429,240.717,466.749,251.298
          " />
          <g opacity="0.15">
            <g>
              <defs>
                <rect id="SVGID_9_" x="425.419" y="226.857" width="47.807" height="78.04" />
              </defs>
              <clipPath id="SVGID_10_">
                <use xlinkHref="#SVGID_9_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_10_)" fill="#548EED" d="M466.749,251.298c-6.939,7.59-19.67,20.079-27.07,32.14
              c-0.02,0.03-0.04,0.06-0.05,0.09c-2.89,4.73-4.96,9.39-5.53,13.66l-0.52,7.71l-3.17-1.83l-0.53-0.31l-1.31-0.75
              c-1.877-1.088-3.056-3.075-3.114-5.244c-0.509-18.801,4.515-34.782,9.374-44.455c6.65-13.21,19.11-26.682,29.941-25.361
              C474.839,228.178,476.429,240.717,466.749,251.298" />
            </g>
          </g>
          <path fill="#FFFFFF" d="M461.639,234.237c-13.26,8.38-32.189,37.59-31.76,68.52l-1.31-0.75c-0.11-31.119,18.979-60.399,32.37-68.87
          c0.31-0.199,0.71-0.1,0.899,0.211C462.029,233.647,461.939,234.047,461.639,234.237" />
          <path fill="#548EED" d="M474.609,276.077c-2.311,6.27-15.49,11.71-25.221,20.76c-2.557,2.384-4.785,4.84-6.673,7.253
          c-1.883,2.407-5.252,3.048-7.896,1.518l-1.16-0.67l-0.08-0.04l-3.17-1.83c1.88-7.08,5.26-13.79,9.22-19.54
          c0.01-0.03,0.03-0.061,0.05-0.09c6.44-9.341,14.391-16.091,19.94-17.62C470.529,262.817,477.379,268.547,474.609,276.077" />
          <g opacity="0.3">
            <g>
              <defs>
                <rect id="SVGID_11_" x="430.409" y="265.005" width="44.815" height="41.43" />
              </defs>
              <clipPath id="SVGID_12_">
                <use xlinkHref="#SVGID_11_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_12_)" fill="#548EED" d="M474.609,276.077c-2.311,6.27-15.49,11.71-25.221,20.76
              c-2.557,2.384-4.785,4.84-6.673,7.253c-1.883,2.407-5.252,3.048-7.896,1.518l-1.16-0.67l-0.08-0.04l-3.17-1.83
              c1.88-7.08,5.26-13.79,9.22-19.54c0.01-0.03,0.03-0.061,0.05-0.09c6.44-9.341,14.391-16.091,19.94-17.62
              C470.529,262.817,477.379,268.547,474.609,276.077" />
            </g>
          </g>
          <path fill="#FFFFFF" d="M465.339,272.307c-7.97,2.25-23.41,11.971-30.52,33.301l-1.16-0.67c7.21-21.591,22.56-31.41,31.33-33.881
          c0.359-0.1,0.7,0.101,0.8,0.45C465.889,271.857,465.689,272.217,465.339,272.307" />
          <rect x="6.714" y={-3} fill="none" width={500} height={500} />
          <path fill="#D3D3D3" d="M79.813,356.309c0,3.47,2.598,7.782,5.801,9.632l126.285,72.926c3.205,1.851,8.398,1.851,11.602,0
          l222.377-128.389c3.203-1.85,5.801-6.162,5.801-9.632c0-3.471-2.597-7.783-5.801-9.632l-126.286-72.927
          c-3.204-1.85-8.397-1.85-11.602,0L85.612,346.677C82.409,348.525,79.813,352.839,79.813,356.309" />
          <path fill="#AEAEAF" d="M451.683,300.847c0,3.473-2.6,7.778-5.803,9.629L223.499,438.871c-1.602,0.915-3.703,1.383-5.793,1.383
          v-12.562c2.09,0,4.191-0.468,5.793-1.394L445.88,297.914c2.806-1.617,3.156-4.12,1.052-5.96
          C449.627,294.048,451.683,297.768,451.683,300.847" />
          <path fill="#888889" d="M217.704,427.698v12.552c-2.104,0-4.207-0.455-5.801-1.38L85.611,365.941
          c-3.202-1.85-5.801-6.162-5.801-9.632c0-3.255,2.291-7.247,5.211-9.257c-2.599,1.862-2.397,4.595,0.59,6.323l126.292,72.93
          C213.497,427.229,215.601,427.698,217.704,427.698" />
          <path fill="#253A7C" d="M74.372,346.884c-2.836-2-2.842-4.665-2.842-8.364l-0.002-186.876c0-3.698,2.598-8.196,5.801-10.046
          L299.706,13.209c3.203-1.85,6.158-0.51,9.164,1.225c3.005,1.736,4.921,3.232,4.921,6.932V208.24c0,3.699-2.597,8.197-5.801,10.047
          L85.612,346.676C80.764,349.946,77.458,349.061,74.372,346.884" />
          <path fill="#262626" d="M85.612,346.679c-4.848,3.264-8.16,2.381-11.24,0.202c-2.832-1.997-2.842-4.665-2.842-8.361V151.646
          c0-1.852,0.653-3.896,1.689-5.711l8.285,4.819c-1.047,1.804-1.689,3.849-1.689,5.692v186.882
          C79.815,347.024,82.407,348.521,85.612,346.679" />
          <path fill="#111111" d="M313.793,21.359c0-3.69-2.6-5.188-5.803-3.348L85.608,146.406c-1.601,0.927-3.057,2.517-4.097,4.336
          l-8.276-4.813h-0.012c1.051-1.819,2.508-3.411,4.108-4.337l222.37-128.385c3.203-1.852,6.158-0.512,9.163,1.225
          C311.87,16.169,313.793,17.669,313.793,21.359" />
          <path fill="#262626" d="M88.733,153.096L303.133,29.345c1.923-1.11,3.48-0.21,3.48,2.009V202.76c0,2.219-1.558,4.918-3.48,6.027
          L88.733,332.537c-1.922,1.109-3.48,0.21-3.48-2.01V159.123C85.253,156.904,86.812,154.205,88.733,153.096" />
          <path fill="#548EED" d="M88.733,153.096c-1.922,1.109-3.48,3.809-3.48,6.027v170.371l213.139-123.021
          c1.922-1.109,3.48-3.809,3.48-6.027V30.072L88.733,153.096z" />
          <g opacity="0.65">
            <g>
              <defs>
                <rect id="SVGID_13_" x="85.253" y="30.072" width="216.619" height="299.422" />
              </defs>
              <clipPath id="SVGID_14_">
                <use xlinkHref="#SVGID_13_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_14_)" fill="#FFFFFF" d="M88.733,153.096c-1.922,1.109-3.48,3.809-3.48,6.027v170.371
              l213.139-123.021c1.922-1.109,3.48-3.809,3.48-6.027V30.072L88.733,153.096z" />
            </g>
          </g>
          <path fill="#FFFFFF" d="M101.665,347.019l206.414-119.171c1.602-0.926,4.198-0.926,5.801,0l6.005,3.154
          c1.602,0.925,1.602,2.424,0,3.35L113.439,353.504c-1.603,0.924-4.198,0.924-5.801,0l-5.974-3.136
          C100.063,349.443,100.063,347.944,101.665,347.019" />
          <path fill="#FFFFFF" d="M117.854,356.646l206.412-119.174c1.602-0.925,3.975-1.054,5.3-0.289l53.725,31.021
          c1.327,0.764,1.103,2.134-0.5,3.059L176.62,390.275c-1.602,0.925-3.978,1.058-5.307,0.297l-53.954-30.875
          C116.031,358.937,116.253,357.571,117.854,356.646" />
          <path fill="#5D5D5D" d="M295.009,287.039l6.463-3.731c0.274-0.158,0.314-0.392,0.091-0.521l-5.701-3.292
          c-0.226-0.13-0.63-0.105-0.905,0.053l-6.463,3.73c-0.274,0.158-0.314,0.393-0.089,0.522l5.7,3.292
          C294.33,287.221,294.734,287.197,295.009,287.039 M285.991,274.37l-6.463,3.73c-0.274,0.159-0.314,0.394-0.09,0.523l5.701,3.291
          c0.225,0.13,0.63,0.106,0.902-0.052l6.464-3.731c0.275-0.159,0.315-0.394,0.091-0.522l-5.701-3.292
          C286.671,274.188,286.266,274.212,285.991,274.37 M265.111,286.426l-10.937,6.313c-0.275,0.158-0.315,0.394-0.09,0.522l5.7,3.291
          c0.226,0.13,0.63,0.107,0.904-0.052l10.937-6.314c0.275-0.158,0.315-0.393,0.091-0.521l-5.701-3.292
          C265.791,286.244,265.386,286.267,265.111,286.426 M276.546,279.823l-6.464,3.731c-0.273,0.158-0.314,0.393-0.089,0.522
          l5.701,3.292c0.223,0.129,0.629,0.105,0.903-0.053l6.463-3.731c0.273-0.158,0.315-0.393,0.09-0.522l-5.701-3.291
          C277.226,279.642,276.819,279.665,276.546,279.823 M251.191,294.462l-6.462,3.73c-0.275,0.159-0.314,0.393-0.091,0.522l5.7,3.292
          c0.226,0.13,0.631,0.105,0.906-0.052l6.462-3.732c0.274-0.158,0.314-0.392,0.089-0.521l-5.699-3.292
          C251.872,294.279,251.466,294.304,251.191,294.462 M241.747,299.915l-6.464,3.73c-0.274,0.159-0.315,0.394-0.091,0.523l5.702,3.291
          c0.226,0.13,0.629,0.106,0.903-0.053l6.463-3.73c0.275-0.159,0.316-0.392,0.091-0.522l-5.701-3.291
          C242.425,299.733,242.021,299.756,241.747,299.915 M265.695,314.316l6.463-3.731c0.273-0.158,0.314-0.393,0.09-0.522l-5.701-3.291
          c-0.225-0.13-0.63-0.106-0.904,0.052l-6.463,3.731c-0.274,0.159-0.314,0.392-0.09,0.522l5.701,3.292
          C265.016,314.499,265.421,314.475,265.695,314.316 M256.251,319.77l6.462-3.73c0.274-0.159,0.314-0.393,0.089-0.523l-5.7-3.291
          c-0.226-0.129-0.63-0.106-0.904,0.052l-6.463,3.731c-0.274,0.159-0.315,0.393-0.09,0.521l5.701,3.292
          C255.57,319.952,255.976,319.929,256.251,319.77 M246.804,325.224l6.463-3.731c0.275-0.158,0.314-0.393,0.09-0.522l-5.701-3.291
          c-0.225-0.13-0.63-0.107-0.904,0.051l-6.463,3.732c-0.274,0.158-0.313,0.392-0.09,0.521l5.701,3.291
          C246.124,325.404,246.529,325.382,246.804,325.224 M237.358,330.677l6.463-3.731c0.274-0.158,0.314-0.393,0.09-0.522l-5.701-3.291
          c-0.226-0.129-0.629-0.107-0.904,0.052l-6.463,3.731c-0.274,0.158-0.315,0.392-0.09,0.521l5.701,3.291
          C236.679,330.857,237.083,330.836,237.358,330.677 M285.564,292.493l6.462-3.731c0.274-0.159,0.314-0.393,0.09-0.522l-5.701-3.292
          c-0.225-0.13-0.63-0.105-0.903,0.053l-6.463,3.73c-0.274,0.159-0.315,0.394-0.091,0.522l5.701,3.292
          C284.884,292.675,285.289,292.65,285.564,292.493 M275.141,308.862l14.417-8.323c0.274-0.159,0.314-0.393,0.09-0.522l-14.666-8.467
          c-0.226-0.13-0.63-0.107-0.904,0.051l-7.457,4.306c-0.274,0.159-0.314,0.393-0.091,0.522l8.152,4.706
          c0.225,0.13,0.184,0.364-0.091,0.521l-5.965,3.445c-0.275,0.158-0.315,0.393-0.09,0.521l5.7,3.292
          C274.46,309.045,274.866,309.021,275.141,308.862 M232.3,305.368l-6.463,3.731c-0.273,0.159-0.314,0.394-0.09,0.522l5.701,3.292
          c0.225,0.129,0.631,0.105,0.904-0.053l6.463-3.73c0.273-0.159,0.314-0.394,0.09-0.523l-5.701-3.291
          C232.979,305.187,232.575,305.21,232.3,305.368 M222.854,310.821l-6.463,3.731c-0.275,0.159-0.314,0.393-0.09,0.523l5.701,3.291
          c0.225,0.13,0.629,0.105,0.903-0.053l6.464-3.73c0.274-0.158,0.314-0.394,0.09-0.522l-5.701-3.292
          C223.534,310.64,223.128,310.664,222.854,310.821 M170.335,368.902c0.225,0.13,0.629,0.107,0.903-0.052l6.464-3.731
          c0.273-0.158,0.314-0.393,0.09-0.522l-5.701-3.291c-0.226-0.129-0.631-0.106-0.904,0.052l-6.463,3.731
          c-0.275,0.159-0.314,0.392-0.09,0.522L170.335,368.902z M147.29,354.449l-6.463,3.731c-0.275,0.158-0.316,0.393-0.091,0.522
          l5.701,3.291c0.226,0.13,0.63,0.106,0.903-0.053l6.464-3.73c0.274-0.158,0.315-0.393,0.09-0.522l-5.701-3.291
          C147.968,354.268,147.564,354.291,147.29,354.449 M158.882,365.162c0.226,0.13,0.631,0.105,0.906-0.053l6.463-3.731
          c0.273-0.158,0.313-0.393,0.088-0.521l-5.699-3.292c-0.225-0.13-0.631-0.105-0.905,0.053l-6.463,3.73
          c-0.274,0.159-0.313,0.393-0.089,0.522L158.882,365.162z M185.175,371.156l6.464-3.73c0.274-0.159,0.315-0.392,0.09-0.522
          l-5.701-3.292c-0.226-0.13-0.63-0.105-0.903,0.053l-6.463,3.73c-0.275,0.159-0.315,0.394-0.091,0.523l5.701,3.291
          C184.497,371.339,184.901,371.315,185.175,371.156 M174.827,376.662c0.223,0.13,0.629,0.106,0.903-0.052l6.462-3.731
          c0.275-0.158,0.314-0.393,0.09-0.522l-5.701-3.291c-0.225-0.13-0.629-0.106-0.904,0.053l-6.463,3.73
          c-0.273,0.159-0.314,0.392-0.088,0.522L174.827,376.662z M156.734,348.996l-6.462,3.73c-0.275,0.159-0.314,0.394-0.09,0.523
          l5.701,3.291c0.225,0.13,0.629,0.106,0.904-0.053l6.463-3.73c0.273-0.159,0.314-0.393,0.09-0.522l-5.701-3.291
          C157.415,348.813,157.009,348.838,156.734,348.996 M203.964,321.729l-6.463,3.731c-0.274,0.159-0.315,0.392-0.09,0.521l5.699,3.292
          c0.227,0.13,0.63,0.107,0.905-0.052l6.463-3.731c0.274-0.158,0.313-0.393,0.09-0.521l-5.7-3.292
          C204.643,321.547,204.237,321.57,203.964,321.729 M166.181,343.543l-6.463,3.73c-0.274,0.158-0.314,0.394-0.09,0.522l5.701,3.292
          c0.225,0.13,0.629,0.106,0.904-0.053l6.462-3.73c0.275-0.159,0.315-0.393,0.091-0.522l-5.701-3.292
          C166.86,343.36,166.456,343.384,166.181,343.543 M213.408,316.274l-6.463,3.732c-0.274,0.158-0.313,0.392-0.089,0.521l5.701,3.292
          c0.224,0.129,0.629,0.107,0.904-0.053l6.461-3.73c0.275-0.158,0.315-0.393,0.092-0.522l-5.701-3.292
          C214.089,316.094,213.684,316.117,213.408,316.274 M194.518,327.182l-6.462,3.732c-0.275,0.157-0.316,0.392-0.092,0.521
          l5.703,3.291c0.224,0.13,0.629,0.107,0.903-0.052l6.462-3.731c0.275-0.157,0.316-0.392,0.092-0.521l-5.703-3.291
          C195.197,327.001,194.792,327.023,194.518,327.182 M185.072,332.636l-6.463,3.732c-0.274,0.157-0.315,0.392-0.09,0.521l5.701,3.292
          c0.224,0.13,0.63,0.106,0.903-0.052l6.463-3.731c0.274-0.159,0.315-0.393,0.09-0.522l-5.7-3.291
          C185.751,332.454,185.347,332.478,185.072,332.636 M175.626,338.089l-6.463,3.731c-0.273,0.158-0.314,0.393-0.09,0.522l5.701,3.292
          c0.225,0.129,0.63,0.105,0.904-0.053l6.464-3.731c0.274-0.158,0.313-0.393,0.089-0.522l-5.701-3.291
          C176.306,337.907,175.901,337.931,175.626,338.089 M218.466,341.584l6.464-3.732c0.274-0.158,0.315-0.392,0.09-0.521l-5.701-3.291
          c-0.226-0.13-0.63-0.107-0.903,0.052l-6.463,3.73c-0.275,0.159-0.314,0.393-0.091,0.523l5.701,3.291
          C217.788,341.766,218.192,341.743,218.466,341.584 M306.871,262.314l-6.463,3.732c-0.274,0.158-0.314,0.392-0.091,0.521
          l5.702,3.291c0.225,0.13,0.63,0.107,0.904-0.052l6.463-3.73c0.274-0.159,0.314-0.393,0.09-0.522l-5.701-3.291
          C307.551,262.133,307.146,262.156,306.871,262.314 M295.437,268.917l-6.463,3.73c-0.274,0.158-0.314,0.393-0.09,0.522l5.7,3.292
          c0.226,0.13,0.631,0.105,0.904-0.052l6.464-3.731c0.274-0.159,0.314-0.393,0.09-0.523l-5.701-3.291
          C296.116,268.734,295.71,268.759,295.437,268.917 M316.316,256.861l-6.463,3.731c-0.274,0.158-0.314,0.392-0.09,0.522l5.701,3.291
          c0.225,0.13,0.63,0.107,0.904-0.052l6.463-3.73c0.274-0.159,0.314-0.394,0.09-0.523l-5.7-3.291
          C316.996,256.681,316.592,256.703,316.316,256.861 M263.689,305.122l6.463-3.731c0.274-0.159,0.314-0.393,0.09-0.522l-5.7-3.291
          c-0.226-0.131-0.63-0.106-0.904,0.052l-6.463,3.73c-0.275,0.159-0.315,0.394-0.091,0.523l5.701,3.291
          C263.01,305.304,263.415,305.279,263.689,305.122 M326.666,251.356c-0.225-0.13-0.63-0.106-0.904,0.051l-6.462,3.731
          c-0.274,0.16-0.315,0.393-0.091,0.523l5.701,3.291c0.225,0.13,0.63,0.106,0.904-0.052l6.463-3.731
          c0.274-0.158,0.315-0.393,0.09-0.522L326.666,251.356z M227.913,336.13l6.463-3.731c0.273-0.159,0.313-0.392,0.088-0.521
          l-5.699-3.292c-0.225-0.13-0.631-0.106-0.904,0.052l-6.464,3.732c-0.274,0.157-0.313,0.392-0.089,0.521l5.701,3.292
          C227.232,336.312,227.639,336.289,227.913,336.13 M343.746,269.254l6.462-3.731c0.275-0.159,0.315-0.393,0.091-0.522l-5.701-3.291
          c-0.226-0.13-0.63-0.107-0.904,0.052l-6.463,3.731c-0.274,0.158-0.315,0.393-0.09,0.521l5.7,3.292
          C343.066,269.435,343.471,269.412,343.746,269.254 M325.334,269.531l6.464-3.731c0.274-0.159,0.314-0.393,0.089-0.522l-5.7-3.291
          c-0.225-0.131-0.63-0.106-0.904,0.052l-6.463,3.73c-0.274,0.159-0.315,0.394-0.09,0.523l5.701,3.291
          C324.654,269.713,325.061,269.688,325.334,269.531 M335.632,256.532c-0.225-0.13-0.631-0.106-0.904,0.053l-6.463,3.73
          c-0.273,0.158-0.314,0.393-0.09,0.522l5.701,3.291c0.225,0.13,0.63,0.106,0.903-0.051l6.464-3.731
          c0.274-0.159,0.315-0.393,0.09-0.522L335.632,256.532z M337.621,255.385l14.666,8.468c0.225,0.129,0.63,0.106,0.904-0.052
          l6.463-3.731c0.274-0.158,0.314-0.393,0.09-0.522l-14.666-8.468c-0.226-0.13-0.63-0.106-0.905,0.053l-6.463,3.73
          C337.437,255.021,337.396,255.255,337.621,255.385 M315.837,267.492l-6.463,3.73c-0.274,0.158-0.315,0.393-0.091,0.522l5.702,3.291
          c0.224,0.13,0.629,0.106,0.904-0.053l6.462-3.73c0.274-0.159,0.315-0.393,0.09-0.522l-5.7-3.292
          C316.517,267.31,316.111,267.333,315.837,267.492 M244.747,308.535l-6.465,3.731c-0.273,0.159-0.314,0.394-0.089,0.522l5.701,3.291
          c0.224,0.13,0.629,0.106,0.903-0.052l6.463-3.73c0.275-0.159,0.314-0.394,0.09-0.523l-5.701-3.291
          C245.425,308.354,245.021,308.377,244.747,308.535 M333.396,274.759c0.224,0.13,0.629,0.106,0.904-0.052l6.463-3.731
          c0.273-0.159,0.314-0.392,0.09-0.521l-5.701-3.292c-0.225-0.13-0.63-0.106-0.904,0.052l-6.463,3.731
          c-0.274,0.158-0.315,0.393-0.09,0.522L333.396,274.759z M254.191,303.082l-6.463,3.731c-0.274,0.159-0.313,0.393-0.089,0.522
          l5.699,3.291c0.226,0.13,0.631,0.106,0.904-0.051l6.464-3.732c0.274-0.159,0.314-0.392,0.089-0.522l-5.7-3.291
          C254.872,302.9,254.466,302.924,254.191,303.082 M179.779,363.449c0.226,0.13,0.632,0.106,0.905-0.053l6.463-3.73
          c0.273-0.158,0.314-0.393,0.089-0.522l-5.7-3.291c-0.225-0.13-0.63-0.106-0.904,0.051l-6.463,3.732
          c-0.274,0.158-0.314,0.392-0.09,0.521L179.779,363.449z M169.181,352.163l-6.463,3.731c-0.274,0.158-0.315,0.393-0.09,0.522
          l5.701,3.291c0.225,0.13,0.629,0.106,0.903-0.053l6.463-3.73c0.275-0.159,0.315-0.393,0.091-0.522l-5.701-3.292
          C169.86,351.98,169.456,352.005,169.181,352.163 M190.13,357.943l6.463-3.73c0.275-0.158,0.314-0.393,0.091-0.523l-5.702-3.29
          c-0.225-0.13-0.629-0.107-0.904,0.053l-6.463,3.729c-0.273,0.159-0.314,0.393-0.09,0.522l5.702,3.291
          C189.45,358.125,189.855,358.103,190.13,357.943 M199.575,352.49l6.463-3.73c0.275-0.159,0.315-0.392,0.09-0.522l-5.701-3.292
          c-0.223-0.13-0.629-0.106-0.904,0.053l-6.462,3.73c-0.274,0.159-0.315,0.393-0.09,0.522l5.7,3.291
          C198.896,352.673,199.302,352.648,199.575,352.49 M209.021,347.037l6.462-3.731c0.275-0.159,0.314-0.392,0.091-0.521l-5.7-3.292
          c-0.226-0.13-0.631-0.106-0.906,0.053l-6.461,3.73c-0.275,0.158-0.315,0.393-0.09,0.522l5.699,3.291
          C208.341,347.219,208.747,347.195,209.021,347.037 M178.626,346.71l-6.463,3.73c-0.273,0.158-0.313,0.393-0.09,0.523l5.701,3.291
          c0.225,0.129,0.63,0.106,0.904-0.053l6.463-3.73c0.275-0.159,0.314-0.393,0.09-0.523l-5.701-3.291
          C179.306,346.528,178.9,346.552,178.626,346.71 M188.072,341.256l-6.463,3.731c-0.274,0.158-0.315,0.393-0.09,0.522l5.701,3.292
          c0.225,0.129,0.63,0.105,0.903-0.053l6.463-3.731c0.274-0.158,0.314-0.392,0.09-0.521l-5.7-3.292
          C188.751,341.074,188.347,341.098,188.072,341.256 M225.854,319.442l-6.463,3.731c-0.275,0.159-0.314,0.392-0.09,0.522l5.701,3.291
          c0.225,0.131,0.629,0.106,0.903-0.053l6.464-3.73c0.274-0.158,0.314-0.393,0.09-0.522l-5.701-3.291
          C226.533,319.261,226.13,319.284,225.854,319.442 M216.408,324.896l-6.463,3.732c-0.274,0.158-0.315,0.392-0.09,0.521l5.701,3.291
          c0.225,0.13,0.631,0.106,0.903-0.052l6.463-3.731c0.275-0.158,0.316-0.393,0.092-0.521l-5.701-3.292
          C217.089,324.714,216.684,324.737,216.408,324.896 M235.3,313.988l-6.463,3.732c-0.274,0.158-0.314,0.392-0.09,0.522l5.7,3.292
          c0.226,0.129,0.63,0.105,0.905-0.054l6.463-3.73c0.275-0.159,0.314-0.393,0.089-0.522l-5.7-3.292
          C235.979,313.807,235.574,313.831,235.3,313.988 M197.519,335.803l-6.463,3.732c-0.275,0.157-0.316,0.392-0.092,0.521l5.701,3.292
          c0.226,0.129,0.631,0.107,0.905-0.052l6.462-3.731c0.275-0.159,0.315-0.393,0.092-0.522l-5.703-3.291
          C198.196,335.622,197.792,335.644,197.519,335.803 M206.962,330.349l-6.461,3.732c-0.275,0.158-0.315,0.392-0.09,0.521l5.7,3.292
          c0.224,0.129,0.63,0.106,0.904-0.052l6.463-3.732c0.274-0.157,0.315-0.392,0.09-0.521l-5.7-3.291
          C207.644,330.168,207.237,330.191,206.962,330.349 M194.622,365.703l6.463-3.731c0.273-0.158,0.313-0.392,0.09-0.522l-5.701-3.29
          c-0.225-0.131-0.631-0.107-0.905,0.052l-6.463,3.73c-0.274,0.158-0.313,0.393-0.089,0.522l5.699,3.292
          C193.941,365.885,194.347,365.861,194.622,365.703 M144.449,363.142l-5.7-3.291c-0.227-0.129-0.631-0.105-0.906,0.052l-6.463,3.732
          c-0.273,0.158-0.313,0.391-0.088,0.521l5.699,3.291c0.225,0.131,0.631,0.107,0.905-0.052l6.462-3.73
          C144.634,363.506,144.673,363.271,144.449,363.142 M156.895,366.309l-5.701-3.291c-0.226-0.129-0.63-0.106-0.903,0.053l-9.943,5.74
          c-0.275,0.158-0.315,0.393-0.09,0.522l5.701,3.291c0.225,0.13,0.629,0.106,0.903-0.053l9.943-5.739
          C157.079,366.673,157.12,366.438,156.895,366.309 M136.057,356.574l-5.701-3.29c-0.226-0.131-0.629-0.107-0.903,0.051l-7.955,4.593
          c-0.274,0.158-0.315,0.393-0.09,0.521l5.7,3.293c0.226,0.129,0.63,0.105,0.903-0.054l7.955-4.592
          C136.241,356.938,136.281,356.704,136.057,356.574 M142.154,353.054c0.224,0.13,0.63,0.106,0.904-0.052l6.463-3.732
          c0.274-0.158,0.315-0.391,0.09-0.521l-5.7-3.292c-0.225-0.13-0.631-0.106-0.906,0.052l-6.461,3.731
          c-0.275,0.158-0.315,0.393-0.09,0.522L142.154,353.054z M168.346,370.051l-5.7-3.292c-0.225-0.129-0.63-0.106-0.905,0.053
          l-12.428,7.175c-0.274,0.159-0.315,0.393-0.09,0.522l5.7,3.292c0.225,0.13,0.629,0.105,0.904-0.052l12.428-7.176
          C168.53,370.414,168.57,370.181,168.346,370.051 M172.837,377.81l-5.701-3.291c-0.225-0.13-0.629-0.106-0.903,0.052l-7.955,4.593
          c-0.274,0.158-0.315,0.393-0.089,0.521l5.7,3.292c0.225,0.13,0.63,0.106,0.903-0.052l7.955-4.593
          C173.021,378.174,173.062,377.939,172.837,377.81 M212.384,312.507c0.224,0.131,0.63,0.106,0.904-0.051l6.463-3.732
          c0.273-0.158,0.314-0.392,0.09-0.521l-5.7-3.291c-0.226-0.131-0.632-0.106-0.906,0.052l-6.462,3.73
          c-0.273,0.159-0.314,0.393-0.09,0.522L212.384,312.507z M193.493,323.414c0.225,0.13,0.629,0.106,0.903-0.052l6.463-3.731
          c0.274-0.158,0.315-0.393,0.089-0.522l-5.699-3.291c-0.226-0.131-0.631-0.106-0.904,0.052l-6.463,3.73
          c-0.275,0.159-0.315,0.394-0.09,0.523L193.493,323.414z M151.6,347.6c0.225,0.13,0.631,0.108,0.903-0.051l6.463-3.731
          c0.275-0.158,0.316-0.393,0.092-0.522l-5.702-3.291c-0.224-0.13-0.629-0.107-0.903,0.052l-6.464,3.731
          c-0.274,0.158-0.313,0.393-0.09,0.521L151.6,347.6z M202.938,317.96c0.225,0.131,0.629,0.107,0.904-0.051l6.463-3.731
          c0.273-0.159,0.314-0.393,0.09-0.523l-5.701-3.291c-0.225-0.129-0.63-0.106-0.904,0.053l-6.463,3.73
          c-0.275,0.159-0.314,0.393-0.091,0.523L202.938,317.96z M184.046,328.868c0.226,0.129,0.631,0.105,0.906-0.054l6.461-3.73
          c0.275-0.158,0.315-0.393,0.09-0.522l-5.699-3.291c-0.225-0.13-0.631-0.106-0.905,0.052l-6.463,3.731
          c-0.274,0.159-0.313,0.393-0.089,0.523L184.046,328.868z M178.091,378.547c-0.226-0.129-0.631-0.107-0.904,0.052l-9.943,5.741
          c-0.275,0.158-0.314,0.392-0.09,0.521l5.701,3.291c0.225,0.13,0.629,0.107,0.904-0.052l9.943-5.74
          c0.274-0.158,0.314-0.392,0.09-0.522L178.091,378.547z M170.491,336.693c0.225,0.13,0.631,0.106,0.904-0.052l6.463-3.73
          c0.275-0.159,0.314-0.394,0.09-0.522l-5.701-3.292c-0.225-0.13-0.629-0.106-0.904,0.052l-6.463,3.731
          c-0.274,0.159-0.314,0.392-0.09,0.521L170.491,336.693z M161.046,342.146c0.225,0.13,0.629,0.107,0.903-0.051l6.464-3.732
          c0.274-0.157,0.314-0.392,0.09-0.521l-5.701-3.291c-0.225-0.13-0.631-0.107-0.904,0.052l-6.463,3.731
          c-0.273,0.159-0.314,0.392-0.09,0.521L161.046,342.146z M353.083,277.515c-0.225-0.13-0.63-0.105-0.904,0.052l-15.908,9.186
          c-0.274,0.158-0.314,0.392-0.09,0.521l5.701,3.291c0.225,0.13,0.63,0.107,0.904-0.052l15.908-9.185
          c0.274-0.158,0.314-0.393,0.09-0.522L353.083,277.515z M332.203,289.569c-0.225-0.13-0.629-0.106-0.904,0.053l-6.464,3.73
          c-0.273,0.159-0.313,0.393-0.089,0.523l5.701,3.291c0.225,0.13,0.63,0.106,0.904-0.053l6.463-3.73
          c0.274-0.159,0.314-0.394,0.09-0.523L332.203,289.569z M204.066,360.25l6.463-3.731c0.275-0.159,0.315-0.393,0.091-0.522
          l-5.701-3.291c-0.226-0.131-0.629-0.107-0.904,0.052l-6.463,3.731c-0.274,0.158-0.314,0.393-0.09,0.522l5.701,3.291
          C203.388,360.432,203.792,360.409,204.066,360.25 M313.313,300.477c-0.226-0.13-0.63-0.106-0.904,0.052l-6.463,3.731
          c-0.275,0.159-0.315,0.393-0.09,0.522l5.7,3.291c0.226,0.131,0.63,0.107,0.903-0.052l6.464-3.731
          c0.274-0.158,0.315-0.393,0.09-0.521L313.313,300.477z M362.529,272.062c-0.226-0.131-0.63-0.106-0.904,0.051l-6.464,3.732
          c-0.273,0.158-0.314,0.393-0.089,0.522l5.7,3.29c0.226,0.131,0.63,0.107,0.904-0.052l6.463-3.73
          c0.274-0.159,0.314-0.393,0.09-0.522L362.529,272.062z M377.676,269.899l-14.667-8.468c-0.225-0.13-0.63-0.107-0.904,0.052
          l-6.463,3.731c-0.274,0.159-0.314,0.393-0.09,0.521l14.667,8.469c0.225,0.13,0.629,0.105,0.903-0.052l6.463-3.731
          C377.86,270.264,377.9,270.029,377.676,269.899 M278.016,320.855c-0.225-0.131-0.63-0.106-0.904,0.052l-7.954,4.593
          c-0.275,0.157-0.315,0.392-0.09,0.521l5.7,3.291c0.226,0.13,0.63,0.107,0.904-0.051l7.954-4.593
          c0.274-0.158,0.315-0.394,0.09-0.522L278.016,320.855z M301.878,307.078c-0.225-0.13-0.63-0.106-0.904,0.052l-9.942,5.741
          c-0.274,0.157-0.315,0.392-0.091,0.521l5.701,3.291c0.225,0.13,0.63,0.107,0.904-0.051l9.943-5.741
          c0.274-0.159,0.314-0.392,0.09-0.521L301.878,307.078z M256.142,333.484c-0.225-0.131-0.631-0.106-0.904,0.052l-53.193,30.712
          c-0.274,0.158-0.315,0.392-0.09,0.521l5.7,3.291c0.224,0.13,0.629,0.107,0.904-0.051l53.192-30.711
          c0.276-0.16,0.316-0.394,0.091-0.523L256.142,333.484z M199.964,365.918c-0.225-0.13-0.629-0.106-0.903,0.052l-7.955,4.592
          c-0.274,0.159-0.313,0.394-0.089,0.523l5.699,3.291c0.226,0.13,0.631,0.106,0.906-0.053l7.955-4.593
          c0.273-0.158,0.313-0.391,0.088-0.521L199.964,365.918z M267.078,327.17c-0.225-0.13-0.63-0.106-0.904,0.052l-7.954,4.592
          c-0.274,0.159-0.315,0.394-0.09,0.523l5.701,3.291c0.224,0.13,0.628,0.106,0.904-0.052l7.954-4.594
          c0.274-0.158,0.314-0.391,0.089-0.521L267.078,327.17z M189.027,372.232c-0.226-0.13-0.63-0.106-0.903,0.053l-7.955,4.592
          c-0.274,0.158-0.314,0.392-0.09,0.521l5.7,3.292c0.226,0.13,0.63,0.106,0.905-0.052l7.954-4.593
          c0.274-0.158,0.315-0.393,0.09-0.522L189.027,372.232z M288.952,314.541c-0.225-0.13-0.629-0.107-0.904,0.052l-7.954,4.592
          c-0.274,0.159-0.314,0.394-0.09,0.522l5.701,3.292c0.225,0.13,0.629,0.105,0.904-0.053l7.954-4.592
          c0.274-0.159,0.314-0.393,0.09-0.522L288.952,314.541z M322.758,295.022c-0.225-0.13-0.63-0.105-0.904,0.054l-6.463,3.73
          c-0.274,0.158-0.314,0.392-0.09,0.522l5.7,3.291c0.226,0.13,0.631,0.105,0.905-0.053l6.462-3.73
          c0.275-0.158,0.314-0.393,0.09-0.522L322.758,295.022z M343.266,279.884l6.463-3.731c0.274-0.158,0.315-0.393,0.09-0.522
          l-5.701-3.291c-0.225-0.13-0.629-0.107-0.904,0.052l-6.463,3.731c-0.273,0.157-0.314,0.392-0.09,0.521l5.701,3.291
          C342.585,280.064,342.991,280.042,343.266,279.884 M304.455,281.586l6.462-3.731c0.275-0.158,0.315-0.393,0.091-0.522l-5.701-3.291
          c-0.225-0.13-0.63-0.107-0.904,0.052l-6.463,3.731c-0.275,0.158-0.314,0.393-0.09,0.522l5.7,3.291
          C303.775,281.768,304.18,281.744,304.455,281.586 M279.632,316.622l18.892-10.906c0.274-0.159,0.314-0.394,0.09-0.522l-5.701-3.292
          c-0.225-0.129-0.629-0.106-0.904,0.053l-18.891,10.906c-0.275,0.158-0.315,0.393-0.09,0.523l5.7,3.29
          C278.953,316.804,279.357,316.781,279.632,316.622 M332.916,285.389c0.225,0.13,0.63,0.106,0.904-0.052l6.462-3.731
          c0.275-0.159,0.315-0.392,0.091-0.522l-5.701-3.291c-0.225-0.13-0.63-0.106-0.904,0.052l-6.463,3.732
          c-0.274,0.157-0.314,0.392-0.09,0.521L332.916,285.389z M324.854,280.161l6.463-3.731c0.274-0.159,0.315-0.393,0.09-0.522
          l-5.7-3.292c-0.226-0.13-0.631-0.105-0.904,0.053l-6.463,3.73c-0.275,0.159-0.315,0.393-0.091,0.522l5.701,3.292
          C324.175,280.343,324.58,280.318,324.854,280.161 M270.187,322.076l6.463-3.731c0.274-0.159,0.314-0.392,0.09-0.522l-5.701-3.292
          c-0.225-0.13-0.63-0.105-0.904,0.053l-6.463,3.731c-0.274,0.158-0.314,0.393-0.09,0.521l5.701,3.292
          C269.507,322.258,269.911,322.234,270.187,322.076 M312.036,297.443c0.225,0.13,0.63,0.107,0.904-0.052l6.463-3.731
          c0.274-0.158,0.314-0.393,0.09-0.521l-5.701-3.291c-0.225-0.131-0.63-0.107-0.904,0.051l-6.463,3.731
          c-0.274,0.159-0.314,0.393-0.091,0.522L312.036,297.443z M222.958,349.343l6.463-3.731c0.274-0.158,0.314-0.392,0.09-0.521
          l-5.7-3.291c-0.226-0.131-0.63-0.107-0.905,0.051l-6.463,3.732c-0.273,0.157-0.314,0.392-0.09,0.521l5.702,3.291
          C222.278,349.524,222.684,349.502,222.958,349.343 M232.403,343.889l6.463-3.729c0.275-0.159,0.315-0.394,0.09-0.522l-5.701-3.292
          c-0.225-0.13-0.629-0.107-0.903,0.052l-6.463,3.731c-0.274,0.158-0.315,0.393-0.091,0.522l5.701,3.291
          C231.725,344.071,232.13,344.049,232.403,343.889 M213.513,354.796l6.463-3.731c0.275-0.158,0.314-0.392,0.089-0.521l-5.7-3.291
          c-0.225-0.13-0.63-0.107-0.904,0.052l-6.463,3.731c-0.274,0.157-0.314,0.392-0.09,0.521l5.7,3.292
          C212.833,354.978,213.237,354.955,213.513,354.796 M251.294,332.982l6.464-3.73c0.274-0.159,0.315-0.394,0.09-0.523l-5.701-3.291
          c-0.224-0.13-0.629-0.105-0.903,0.052l-6.464,3.732c-0.274,0.158-0.315,0.391-0.09,0.521l5.701,3.292
          C250.615,333.165,251.021,333.141,251.294,332.982 M241.85,338.436l6.463-3.73c0.274-0.158,0.315-0.393,0.09-0.522l-5.7-3.292
          c-0.226-0.129-0.631-0.105-0.906,0.053l-6.461,3.731c-0.275,0.158-0.315,0.392-0.09,0.521l5.7,3.292
          C241.171,338.618,241.575,338.595,241.85,338.436 M260.74,327.529l6.464-3.731c0.273-0.158,0.314-0.393,0.09-0.522l-5.701-3.291
          c-0.226-0.13-0.63-0.106-0.904,0.053l-6.463,3.73c-0.275,0.158-0.314,0.393-0.09,0.522l5.699,3.292
          C260.062,327.711,260.466,327.688,260.74,327.529 M312.935,252.039c0.225,0.13,0.63,0.106,0.904-0.053l6.463-3.73
          c0.274-0.158,0.314-0.393,0.09-0.522l-1.518-0.876c-0.225-0.131-0.63-0.107-0.904,0.053l-6.463,3.73
          c-0.273,0.158-0.314,0.392-0.09,0.522L312.935,252.039z M265.81,281.661c0.225,0.13,0.63,0.107,0.904-0.052l6.463-3.73
          c0.274-0.158,0.314-0.394,0.09-0.523l-5.701-3.29c-0.225-0.131-0.63-0.107-0.904,0.052l-6.463,3.73
          c-0.274,0.159-0.314,0.393-0.09,0.523L265.81,281.661z M284.701,270.755c0.225,0.13,0.63,0.105,0.903-0.052l6.463-3.731
          c0.275-0.159,0.315-0.393,0.091-0.522l-5.701-3.292c-0.226-0.13-0.63-0.105-0.904,0.053l-6.463,3.731
          c-0.274,0.158-0.315,0.393-0.09,0.522L284.701,270.755z M254.275,288.32c0.226,0.131,0.63,0.107,0.905-0.052l6.463-3.73
          c0.273-0.158,0.314-0.394,0.089-0.523l-5.7-3.291c-0.225-0.129-0.63-0.105-0.904,0.053l-6.463,3.731
          c-0.274,0.158-0.314,0.392-0.09,0.521L254.275,288.32z M275.255,276.208c0.225,0.131,0.63,0.106,0.905-0.052l6.462-3.731
          c0.274-0.158,0.315-0.393,0.09-0.521l-5.701-3.292c-0.225-0.13-0.629-0.106-0.903,0.052l-6.463,3.731
          c-0.274,0.159-0.315,0.393-0.091,0.522L275.255,276.208z M235.384,299.228c0.226,0.129,0.631,0.106,0.904-0.052l6.463-3.731
          c0.274-0.158,0.315-0.393,0.09-0.522l-5.7-3.291c-0.226-0.13-0.63-0.107-0.904,0.052l-6.463,3.731
          c-0.274,0.159-0.313,0.392-0.09,0.521L235.384,299.228z M244.831,293.773c0.224,0.13,0.629,0.107,0.903-0.051l6.463-3.732
          c0.274-0.157,0.313-0.392,0.089-0.521l-5.699-3.291c-0.226-0.13-0.631-0.107-0.904,0.051l-6.463,3.732
          c-0.275,0.158-0.315,0.392-0.09,0.521L244.831,293.773z M294.042,262.945c0.226,0.131,0.631,0.107,0.905-0.052l6.463-3.731
          c0.274-0.158,0.314-0.392,0.09-0.521l-1.518-0.877c-0.225-0.129-0.63-0.106-0.903,0.052l-6.463,3.732
          c-0.275,0.158-0.315,0.392-0.091,0.521L294.042,262.945z M322.38,246.584c0.226,0.131,0.63,0.107,0.904-0.051l6.463-3.73
          c0.274-0.159,0.315-0.393,0.09-0.521l-1.518-0.877c-0.225-0.129-0.63-0.107-0.904,0.051l-6.463,3.731
          c-0.274,0.159-0.314,0.394-0.09,0.522L322.38,246.584z M334.355,253.5c0.226,0.129,0.63,0.105,0.904-0.053l6.463-3.73
          c0.275-0.159,0.314-0.393,0.091-0.522l-5.701-3.292c-0.225-0.13-0.63-0.106-0.904,0.051l-6.463,3.732
          c-0.275,0.159-0.315,0.394-0.09,0.522L334.355,253.5z M352.71,274.431l6.464-3.732c0.275-0.158,0.315-0.392,0.091-0.521
          l-5.701-3.291c-0.225-0.131-0.63-0.107-0.904,0.051l-6.463,3.731c-0.274,0.159-0.314,0.393-0.09,0.521l5.7,3.292
          C352.031,274.611,352.437,274.589,352.71,274.431 M303.489,257.492c0.225,0.13,0.629,0.106,0.904-0.052l6.463-3.731
          c0.274-0.158,0.314-0.393,0.089-0.522l-1.517-0.876c-0.226-0.13-0.63-0.106-0.905,0.052l-6.463,3.731
          c-0.273,0.159-0.314,0.393-0.089,0.523L303.489,257.492z M225.938,304.681c0.225,0.13,0.63,0.107,0.904-0.052l6.463-3.731
          c0.275-0.159,0.316-0.392,0.091-0.521l-5.702-3.292c-0.224-0.13-0.63-0.106-0.904,0.052l-6.463,3.732
          c-0.273,0.157-0.313,0.392-0.089,0.521L225.938,304.681z" />
          <polygon fill="#AEAEAF" points="254.858,353.459 256.185,354.223 291.38,374.54 353.975,338.4 355.277,337.658 318.755,316.576 	
          " />
          <polygon fill="#FAFAFA" points="353.391,338.738 318.756,318.756 256.767,354.559 291.381,374.54 	" />
          <rect x="6.714" y={-3} fill="none" width={500} height={500} />
          <path fill="#548EED" d="M392.932,60.424c0-2.636-0.92-4.492-2.408-5.367c-1.507-0.885-10.229-5.918-11.746-6.78
          c-1.503-0.856-3.572-0.726-5.857,0.594l-163.257,94.257l0.002,54.029l11.697,6.753l163.256-94.256
          c4.592-2.649,8.313-9.097,8.313-14.397V60.424z" />
          <g>
            <defs>
              <rect id="SVGID_15_" x="209.664" y="47.734" width="183.268" height="156.175" />
            </defs>
            <clipPath id="SVGID_16_">
              <use xlinkHref="#SVGID_15_" overflow="visible" />
            </clipPath>
            <path clipPath="url(#SVGID_16_)" fill="#3F51B5" d="M392.932,60.424c0-2.636-0.92-4.492-2.408-5.367
            c-1.507-0.885-10.229-5.918-11.746-6.78c-1.503-0.856-3.572-0.726-5.857,0.594l-163.257,94.257l0.002,54.029l11.697,6.753
            l163.256-94.256c4.592-2.649,8.313-9.097,8.313-14.397V60.424z" />
          </g>
          <path fill="#548EED" d="M384.618,55.623c4.591-2.65,8.313-0.502,8.313,4.801v34.83c0,5.303-3.721,11.748-8.313,14.399
          L221.363,203.91l-0.001-54.031L384.618,55.623z" />
          <path fill="#F9F9F9" d="M382.942,105.205c3.082-1.78,5.604-6.147,5.604-9.707V63.32c0-2.535-1.797-3.574-3.993-2.305
          l-159.903,92.386v43.261L382.942,105.205z" />
          <path fill="#111111" d="M252.519,169.976c0,0.248-0.059,0.495-0.176,0.741c-0.117,0.249-0.256,0.419-0.417,0.512l-2.761,1.596
          c-0.162,0.092-0.301,0.082-0.418-0.031c-0.117-0.111-0.174-0.291-0.174-0.539v-8.541c0-0.967-0.154-1.654-0.461-2.063
          c-0.308-0.406-0.789-0.422-1.447-0.041c-0.658,0.379-1.137,0.949-1.437,1.705c-0.299,0.76-0.448,1.622-0.448,2.59v8.539
          c0,0.248-0.06,0.495-0.177,0.742c-0.116,0.248-0.255,0.418-0.416,0.512l-2.763,1.594c-0.161,0.094-0.301,0.084-0.416-0.029
          c-0.118-0.112-0.176-0.291-0.176-0.539V154.58c0-0.248,0.058-0.496,0.176-0.742c0.115-0.248,0.255-0.418,0.416-0.512l2.763-1.595
          c0.161-0.093,0.3-0.082,0.416,0.03c0.117,0.111,0.177,0.292,0.177,0.539v7.189c0.247-0.615,0.635-1.295,1.161-2.037
          c0.526-0.744,1.132-1.312,1.819-1.709c0.701-0.404,1.345-0.625,1.93-0.658c0.584-0.033,1.085,0.145,1.502,0.535
          c0.416,0.389,0.741,1,0.975,1.832c0.234,0.832,0.352,1.914,0.352,3.24V169.976z" />
          <path fill="#111111" d="M260.104,159.656c0,0.518,0.073,0.875,0.219,1.071c0.146,0.196,0.387,0.198,0.725,0.005l1.753-1.014
          c0.16-0.091,0.3-0.082,0.417,0.029c0.115,0.113,0.175,0.293,0.175,0.541v3.408c0,0.248-0.06,0.496-0.175,0.742
          c-0.117,0.248-0.257,0.418-0.417,0.512l-2.082,1.201c-0.688,0.397-1.313,0.605-1.875,0.627s-1.041-0.141-1.437-0.488
          c-0.394-0.344-0.7-0.865-0.92-1.559c-0.219-0.695-0.329-1.561-0.329-2.596v-5.672l-1.621,0.938
          c-0.162,0.092-0.301,0.084-0.418-0.029c-0.117-0.112-0.175-0.293-0.175-0.541v-3.408c0-0.248,0.058-0.495,0.175-0.742
          s0.256-0.418,0.418-0.512l1.621-0.936v-5.502c0-0.248,0.059-0.494,0.176-0.742c0.116-0.248,0.254-0.418,0.416-0.512l2.763-1.594
          c0.16-0.094,0.299-0.082,0.417,0.029c0.116,0.113,0.175,0.293,0.175,0.539v5.502l2.478-1.43c0.16-0.093,0.299-0.082,0.417,0.029
          c0.116,0.113,0.175,0.293,0.175,0.54v3.409c0,0.249-0.059,0.496-0.175,0.742c-0.118,0.248-0.257,0.418-0.417,0.511l-2.478,1.431
          V159.656z" />
          <path fill="#111111" d="M270.495,153.656c0,0.518,0.072,0.875,0.219,1.072c0.146,0.196,0.387,0.197,0.725,0.004l1.753-1.013
          c0.159-0.092,0.299-0.083,0.417,0.028c0.115,0.113,0.175,0.293,0.175,0.541v3.41c0,0.248-0.06,0.494-0.175,0.74
          c-0.118,0.249-0.258,0.42-0.417,0.512l-2.082,1.203c-0.688,0.396-1.313,0.604-1.875,0.625c-0.563,0.021-1.041-0.141-1.437-0.486
          c-0.394-0.346-0.701-0.866-0.92-1.561c-0.219-0.695-0.329-1.561-0.329-2.596v-5.671l-1.622,0.937
          c-0.161,0.094-0.3,0.084-0.417-0.029c-0.117-0.111-0.175-0.291-0.175-0.541v-3.408c0-0.247,0.058-0.494,0.175-0.742
          c0.117-0.246,0.256-0.418,0.417-0.511l1.622-0.937v-5.502c0-0.246,0.059-0.494,0.175-0.742c0.117-0.246,0.256-0.418,0.417-0.511
          l2.763-1.595c0.16-0.094,0.299-0.082,0.417,0.029c0.116,0.113,0.175,0.293,0.175,0.539v5.504l2.478-1.432
          c0.16-0.092,0.299-0.082,0.417,0.03c0.116,0.112,0.175,0.292,0.175,0.54v3.408c0,0.249-0.059,0.496-0.175,0.742
          c-0.118,0.25-0.257,0.418-0.417,0.512l-2.478,1.43V153.656z" />
          <path fill="#111111" d="M282.53,153.896c-0.423,0.244-0.796,0.393-1.117,0.443c-0.322,0.051-0.6,0.043-0.834-0.024
          c-0.233-0.069-0.431-0.175-0.592-0.317c-0.16-0.145-0.292-0.287-0.394-0.431v7.19c0,0.246-0.06,0.494-0.176,0.741
          c-0.117,0.248-0.256,0.418-0.416,0.511l-2.764,1.596c-0.16,0.092-0.299,0.082-0.416-0.031c-0.116-0.111-0.176-0.291-0.176-0.539
          v-22.143c0-0.246,0.06-0.494,0.176-0.742c0.117-0.246,0.256-0.418,0.416-0.51l2.653-1.532c0.16-0.093,0.3-0.082,0.417,0.03
          c0.116,0.111,0.175,0.291,0.175,0.539v0.776c0.131-0.3,0.274-0.619,0.428-0.954c0.153-0.338,0.343-0.666,0.569-0.988
          c0.227-0.321,0.505-0.64,0.833-0.953c0.329-0.314,0.735-0.609,1.217-0.889c0.658-0.379,1.279-0.586,1.864-0.621
          c0.585-0.033,1.097,0.145,1.534,0.533c0.439,0.387,0.793,0.988,1.063,1.799c0.271,0.812,0.42,1.873,0.449,3.183
          c0.015,0.329,0.022,0.782,0.022,1.354c0,0.574-0.008,1.033-0.022,1.381c-0.029,1.344-0.179,2.578-0.449,3.702
          s-0.624,2.132-1.063,3.026c-0.438,0.895-0.949,1.668-1.534,2.32S283.188,153.518,282.53,153.896 M283.518,145.158
          c0.043-0.654,0.043-1.285,0-1.889c-0.044-0.762-0.212-1.352-0.505-1.77c-0.292-0.415-0.781-0.426-1.468-0.029
          c-0.322,0.187-0.6,0.426-0.833,0.718c-0.234,0.292-0.429,0.614-0.581,0.96c-0.154,0.348-0.274,0.703-0.362,1.07
          c-0.088,0.365-0.147,0.725-0.175,1.08c-0.03,0.354-0.044,0.756-0.044,1.207c0,0.449,0.014,0.834,0.044,1.154
          c0.027,0.321,0.087,0.613,0.175,0.879c0.088,0.264,0.208,0.48,0.362,0.651c0.152,0.171,0.347,0.267,0.581,0.288
          c0.233,0.023,0.511-0.059,0.833-0.244c0.687-0.396,1.176-0.973,1.468-1.725C283.306,146.756,283.474,145.971,283.518,145.158" />
          <path fill="#111111" d="M293.535,134.48c0,0.248-0.059,0.496-0.175,0.742c-0.117,0.248-0.256,0.418-0.417,0.512l-2.871,1.658
          c-0.162,0.092-0.301,0.082-0.417-0.031c-0.117-0.111-0.175-0.291-0.175-0.539V132.4c0-0.248,0.058-0.494,0.175-0.742
          c0.116-0.248,0.255-0.418,0.417-0.51l2.871-1.658c0.161-0.094,0.3-0.084,0.417,0.029c0.116,0.112,0.175,0.293,0.175,0.539V134.48z
          M293.535,146.295c0,0.248-0.059,0.494-0.175,0.741c-0.117,0.249-0.256,0.419-0.417,0.511l-2.871,1.658
          c-0.162,0.094-0.301,0.084-0.417-0.029c-0.117-0.113-0.175-0.292-0.175-0.54v-4.422c0-0.247,0.058-0.495,0.175-0.743
          c0.116-0.246,0.255-0.416,0.417-0.51l2.871-1.658c0.161-0.092,0.3-0.082,0.417,0.029c0.116,0.113,0.175,0.293,0.175,0.541V146.295z
          " />
          <path fill="#111111" d="M302.656,116.422c0.072-0.289,0.183-0.582,0.328-0.881c0.146-0.298,0.358-0.527,0.637-0.688l2.718-1.57
          c0.131-0.076,0.244-0.068,0.34,0.023c0.095,0.092,0.143,0.238,0.143,0.441c0,0.09-0.015,0.199-0.044,0.33l-7.388,31.57
          c-0.06,0.258-0.162,0.543-0.307,0.853c-0.146,0.31-0.366,0.548-0.658,0.717l-2.675,1.544c-0.132,0.076-0.245,0.068-0.34-0.023
          c-0.096-0.09-0.143-0.238-0.143-0.439c0-0.09,0.015-0.199,0.044-0.33L302.656,116.422z" />
          <path fill="#111111" d="M310.176,112.08c0.073-0.289,0.183-0.582,0.329-0.881c0.146-0.298,0.357-0.527,0.636-0.688l2.719-1.57
          c0.131-0.076,0.244-0.068,0.34,0.023c0.095,0.092,0.142,0.238,0.142,0.442c0,0.089-0.014,0.198-0.044,0.329l-7.387,31.57
          c-0.06,0.258-0.162,0.543-0.308,0.853c-0.146,0.31-0.366,0.548-0.657,0.717l-2.676,1.544c-0.131,0.076-0.244,0.068-0.339-0.023
          c-0.096-0.09-0.143-0.238-0.143-0.439c0-0.09,0.014-0.199,0.044-0.33L310.176,112.08z" />
          <path fill="#111111" d="M324.667,111.68c0.219-0.126,0.387-0.131,0.504-0.02c0.116,0.111,0.197,0.246,0.241,0.4l1.622,6.725
          l1.623-8.598c0.043-0.205,0.123-0.432,0.24-0.68c0.117-0.247,0.285-0.436,0.505-0.561l2.718-1.569
          c0.146-0.085,0.271-0.078,0.373,0.021c0.103,0.098,0.152,0.26,0.152,0.485c0,0.09-0.006,0.185-0.021,0.282l-3.486,17.641
          c-0.044,0.227-0.12,0.463-0.229,0.705c-0.11,0.244-0.274,0.43-0.494,0.557l-2.499,1.442c-0.219,0.126-0.38,0.13-0.482,0.008
          c-0.102-0.12-0.183-0.266-0.241-0.435l-1.557-6.188l-1.556,7.986c-0.06,0.235-0.14,0.474-0.241,0.713
          c-0.103,0.239-0.264,0.422-0.484,0.549l-2.498,1.442c-0.219,0.127-0.383,0.132-0.493,0.015c-0.109-0.116-0.186-0.264-0.229-0.44
          l-3.487-13.615c-0.014-0.082-0.021-0.167-0.021-0.258c0-0.224,0.052-0.445,0.154-0.662c0.102-0.217,0.226-0.367,0.372-0.451
          l2.719-1.57c0.22-0.126,0.387-0.132,0.505-0.02c0.116,0.111,0.196,0.246,0.24,0.4l1.622,6.726l1.623-8.599
          c0.044-0.205,0.124-0.432,0.241-0.68c0.117-0.247,0.285-0.436,0.503-0.563L324.667,111.68z" />
          <path fill="#111111" d="M343.652,100.719c0.22-0.127,0.387-0.133,0.504-0.021s0.197,0.247,0.241,0.401l1.622,6.725l1.623-8.6
          c0.043-0.203,0.124-0.43,0.241-0.678s0.285-0.436,0.504-0.563l2.719-1.568c0.146-0.085,0.271-0.078,0.373,0.02
          c0.102,0.1,0.152,0.262,0.152,0.486c0,0.09-0.007,0.184-0.021,0.282l-3.486,17.64c-0.044,0.229-0.121,0.465-0.23,0.707
          c-0.109,0.244-0.273,0.428-0.493,0.556l-2.5,1.442c-0.219,0.127-0.379,0.129-0.481,0.008s-0.183-0.266-0.241-0.434l-1.557-6.189
          l-1.557,7.986c-0.059,0.236-0.14,0.475-0.241,0.713c-0.102,0.24-0.263,0.422-0.482,0.549l-2.499,1.443
          c-0.22,0.127-0.384,0.131-0.493,0.014c-0.11-0.115-0.187-0.264-0.23-0.439l-3.486-13.615c-0.014-0.082-0.021-0.168-0.021-0.258
          c0-0.225,0.052-0.445,0.154-0.662c0.101-0.217,0.226-0.367,0.372-0.451l2.719-1.57c0.219-0.127,0.387-0.133,0.504-0.021
          s0.197,0.247,0.241,0.401l1.622,6.725l1.623-8.599c0.044-0.205,0.123-0.431,0.241-0.679c0.116-0.248,0.284-0.436,0.504-0.563
          L343.652,100.719z" />
          <path fill="#111111" d="M362.639,89.758c0.219-0.127,0.387-0.133,0.504-0.021c0.116,0.113,0.197,0.248,0.241,0.401l1.622,6.726
          l1.623-8.6c0.043-0.204,0.123-0.432,0.241-0.679c0.116-0.247,0.284-0.435,0.504-0.562l2.718-1.57
          c0.146-0.084,0.271-0.075,0.373,0.021c0.103,0.1,0.152,0.262,0.152,0.486c0,0.09-0.006,0.184-0.021,0.281l-3.486,17.641
          c-0.044,0.229-0.12,0.464-0.229,0.707c-0.11,0.244-0.274,0.428-0.494,0.555l-2.499,1.443c-0.219,0.127-0.38,0.129-0.482,0.008
          c-0.102-0.121-0.183-0.266-0.241-0.434l-1.557-6.189l-1.556,7.986c-0.06,0.236-0.14,0.475-0.241,0.713
          c-0.103,0.238-0.264,0.422-0.483,0.549l-2.499,1.443c-0.219,0.126-0.383,0.131-0.493,0.014c-0.109-0.115-0.186-0.264-0.229-0.441
          l-3.487-13.614c-0.014-0.081-0.021-0.167-0.021-0.257c0-0.225,0.052-0.445,0.154-0.662c0.102-0.217,0.226-0.367,0.372-0.452
          l2.719-1.569c0.22-0.127,0.387-0.133,0.505-0.021c0.116,0.113,0.196,0.246,0.24,0.402l1.622,6.725l1.623-8.6
          c0.044-0.205,0.124-0.432,0.241-0.679s0.285-0.435,0.504-0.562L362.639,89.758z" />
          <path fill="#111111" d="M375.464,98.993c0,0.248-0.059,0.495-0.176,0.743c-0.116,0.247-0.256,0.417-0.416,0.51L372,101.904
          c-0.161,0.093-0.3,0.083-0.417-0.029c-0.117-0.113-0.175-0.292-0.175-0.54v-4.422c0-0.247,0.058-0.495,0.175-0.743
          c0.117-0.246,0.256-0.416,0.417-0.51l2.872-1.658c0.16-0.093,0.3-0.082,0.416,0.03c0.117,0.112,0.176,0.292,0.176,0.539V98.993z" />
          <path fill="#3F51B5" d="M214.157,134.152l0.002-0.004l-0.078-0.048c-0.004-0.001-0.006-0.003-0.008-0.005l-20.006-11.877
          c-0.007-0.004-0.013-0.007-0.019-0.011l-0.11-0.067l-0.002,0.006c-8.811-5.313-20.568-4.476-33.215,3.781
          c-25.404,16.588-44.766,56.823-43.24,89.866c0.76,16.47,6.567,28.042,15.346,33.455l-0.002,0.004l0.078,0.047
          c0.002,0.002,0.006,0.004,0.008,0.006l20.011,11.88c0.003,0.002,0.006,0.004,0.008,0.005l0.093,0.058l0.002-0.005
          c8.814,5.326,20.581,4.495,33.237-3.77c25.405-16.588,44.767-56.823,43.241-89.866
          C228.743,151.137,222.936,139.566,214.157,134.152" />
          <path fill="#2C6AB5" d="M152.28,260.777l-19.362-11.473h-0.015l-0.084-0.06c-8.773-5.425-14.578-16.997-15.336-33.445
          c-0.682-14.735,2.801-30.896,9.102-45.739l0.127-0.326l19.886,12.203c-6.315,14.844-9.782,31.002-9.103,45.738
          C138.239,243.794,143.815,255.216,152.28,260.777" />
          <path fill="#FFFFFF" d="M186.263,257.474c25.404-16.587,44.765-56.822,43.239-89.866c-1.524-33.043-23.356-46.383-48.761-29.795
          c-25.406,16.59-44.766,56.824-43.241,89.867C139.025,260.723,160.856,274.063,186.263,257.474" />
          <path fill="#548EED" d="M171.923,248.748c-3.397,0-6.594-0.822-9.469-2.475c-8.004-4.604-12.595-15.158-12.595-28.957
          c0-25.388,15.433-55.02,34.403-66.055c8.626-5.02,17.055-5.664,23.732-1.822c8.002,4.602,12.594,15.156,12.594,28.957
          c0,25.385-15.434,55.016-34.402,66.053C181.273,247.308,176.423,248.748,171.923,248.748 M198.519,150.799
          c-3.829,0-8.025,1.264-12.332,3.77c-17.922,10.424-32.501,38.573-32.501,62.748c0,12.393,3.892,21.738,10.675,25.641
          c5.442,3.128,12.512,2.488,19.902-1.814c17.921-10.426,32.5-38.574,32.5-62.746c0-12.394-3.891-21.74-10.676-25.643
          C203.816,151.447,201.262,150.799,198.519,150.799" />
          <path fill="#548EED" d="M171.919,185.268c-4.206,0-8.078-1.311-11.423-3.93l1.771-2.258c5.844,4.58,13.742,4.381,22.236-0.562
          c8.803-5.121,17.073-14.604,23.288-26.705l2.553,1.31c-6.464,12.584-15.128,22.482-24.397,27.875
          C181.056,183.843,176.306,185.268,171.919,185.268" />
          <path fill="#548EED" d="M162.658,243.896l-2.554-1.311c6.463-12.582,15.127-22.48,24.398-27.875
          c9.581-5.57,18.617-5.69,25.449-0.338l-1.77,2.256c-5.846-4.577-13.744-4.377-22.236,0.563
          C177.142,222.313,168.872,231.797,162.658,243.896" />
          <path fill="#548EED" d="M181.521,245.324c-1.328,0-2.632-0.356-3.873-1.071c-6.744-3.878-10.931-17.851-10.931-36.466
          c0-25.663,7.812-50.311,17.786-56.112c2.822-1.642,5.689-1.718,8.3-0.218c6.741,3.878,10.929,17.852,10.929,36.467
          c0,25.662-7.813,50.309-17.785,56.112C184.469,244.895,182.979,245.324,181.521,245.324 M188.921,153.254
          c-0.955,0-1.941,0.3-2.975,0.9c-8.869,5.16-16.36,29.72-16.36,53.633c0,19.701,4.767,31.262,9.489,33.979
          c1.715,0.987,3.492,0.918,5.428-0.21c8.868-5.157,16.359-29.719,16.359-53.632c0-19.702-4.767-31.263-9.49-33.98
          C190.571,153.484,189.759,153.254,188.921,153.254" />
          <rect x="183.79" y="152.915" fill="#548EED" width="2.869" height="89.881" />
          <rect x="183.79" y="159.156" transform="matrix(0.5028 0.8644 -0.8644 0.5028 263.1106 -61.7373)" fill="#548EED" width="2.869" height="77.4" />
          <rect x="6.714" y={-3} fill="none" width={500} height={500} />
          <g>
            <defs>
              <rect id="SVGID_17_" x="6.714" y={-3} width={500} height={500} />
            </defs>
            <clipPath id="SVGID_18_">
              <use xlinkHref="#SVGID_17_" overflow="visible" />
            </clipPath>
            <g opacity="0.8" clipPath="url(#SVGID_18_)">
              <g>
                <defs>
                  <rect id="SVGID_19_" x="374.666" y="117.659" width="45.639" height="65.651" />
                </defs>
                <clipPath id="SVGID_20_">
                  <use xlinkHref="#SVGID_19_" overflow="visible" />
                </clipPath>
              </g>
            </g>
            <g opacity="0.2" clipPath="url(#SVGID_18_)">
              <g>
                <path fill="#548EED" d="M398.857,132.961l0.014,0.008v-0.034L398.857,132.961z" />
              </g>
            </g>
            <g opacity="0.65" clipPath="url(#SVGID_18_)">
              <g>
                <defs>
                  <rect id="SVGID_21_" x="377.621" y="119.364" width="42.684" height="63.946" />
                </defs>
                <clipPath id="SVGID_22_">
                  <use xlinkHref="#SVGID_21_" overflow="visible" />
                </clipPath>
              </g>
            </g>
          </g>
          <g opacity="0.15">
            <g>
              <defs>
                <rect id="SVGID_23_" x="323.344" y="304.992" width="75.532" height="53.029" />
              </defs>
              <clipPath id="SVGID_24_">
                <use xlinkHref="#SVGID_23_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_24_)" fill="#548EED" d="M343.239,308.399c-17.271,8.606-25.11,17.648-16.17,23.984
              c8.941,6.336,33.271,14.914,33.271,14.914l3.189,10.723l35.346-20.406v-12.139c0,0-21.809-15.035-30.325-17.932
              C360.035,304.647,353.362,303.355,343.239,308.399" />
            </g>
          </g>
          <path fill="#FFA9A7" d="M391.978,363.131c0,0-5.338,13.084-5.61,18.245c-0.283,5.378-1.583,7.159-0.671,13.01
          c0.198,1.272,0.867,3.827,1.034,4.861c0.148,0.921-0.197,1.662-1.382,1.907c-2.334,0.484-6.209-3.9-8.054-8.67
          c-1.845-4.771-4.459-15.53-4.621-16.819c-0.157-1.242,0.256-2.787,0.738-3.764c0.846-1.715,1.072-1.95,1.072-1.95
          c0.772-2.341,1.143-6.791,1.794-9.871L391.978,363.131z" />
          <path fill="#262626" d="M368.109,380.568c0,0-0.589,1.073-0.625,1.763c-0.056,1.072,2.367,4.606,3.317,5.894s1.88,2.899,1.75,6.113
          c-0.14,3.435,1.377,6.74,3.595,9.572c2.217,2.832,6.065,5.934,11.73,7.677c5.665,1.744,7.662-1.095,7.662-1.095
          s1.757-2.734,0.701-3.854C395.184,405.518,368.109,380.568,368.109,380.568" />
          <path fill="#5D5D5D" d="M374.484,369.952c-0.172-0.007-0.477-0.056-0.921,0.479c-0.168,0.203-1.201,2.75-2.059,4.087
          c-0.918,1.433-3.107,3.877-3.396,6.049c1.312,4.2,5.251,7.122,5.843,11.088c0.592,3.965,0.658,8.567,6.752,13.421
          c6.095,4.852,12.359,5.353,14.611,4.067c2.252-1.284,2.046-3.696-1.093-8.291c-3.332-4.877-5.576-15.443-5.577-15.448
          c-0.311-1.462-0.472-3.006-0.589-4.494c-0.114-1.447,0.308-2.992,0.421-4.447c0.053-0.688,0.08-1.428-0.285-2.016
          c-0.364-0.586-1.04-0.889-1.676-1.158c-1.017-0.432-2.043-0.865-3.13-1.061c-1.087-0.194-2.254-0.133-3.226,0.391
          c-0.703,0.378-1.278,0.979-2.016,1.285c-0.989,0.409-2.207,0.192-2.992-0.534c-1.158-1.071-0.987-2.035-0.81-2.75
          C374.323,370.599,374.484,369.952,374.484,369.952" />
          <path fill="#F9F9F9" d="M377.143,401.186c0,0,2.361-4.801,8.832-4.776c6.47,0.025,8.709,3.317,9.693,5.253
          c1.172,2.307,2.394,6.485-0.277,7.685c-2.884,1.296-6.225,0.895-11.479-1.739C378.656,404.975,377.143,401.186,377.143,401.186" />
          <path fill="#F9F9F9" d="M378.321,381.695c0.184,0.087,0.402,0.093,0.591-0.003c1-0.509,6.287-1.616,9.367,1.243
          c0.399-0.321,0.318-0.906-0.192-1.73c-3.604-2.289-8.61-1.267-9.816-0.65c-0.316,0.16-0.429,0.547-0.251,0.859
          C378.091,381.541,378.197,381.637,378.321,381.695" />
          <path fill="#F9F9F9" d="M388.945,386.705c0.222-0.572-0.057-1.457-0.374-1.777c-3.555-1.93-8.205-0.965-9.362-0.376
          c-0.316,0.161-0.428,0.546-0.251,0.86c0.071,0.127,0.178,0.222,0.301,0.281c0.185,0.088,0.403,0.094,0.592-0.003
          C380.821,385.196,385.838,384.152,388.945,386.705" />
          <path fill="#F9F9F9" d="M391.584,395.448c0.027-0.792-0.255-1.576-0.827-2.342c-3.592-2.165-8.481-1.165-9.671-0.559
          c-0.316,0.161-0.429,0.547-0.252,0.86c0.072,0.127,0.179,0.222,0.303,0.282c0.184,0.087,0.402,0.093,0.591-0.003
          C382.782,393.149,388.613,391.95,391.584,395.448" />
          <path fill="#F9F9F9" d="M380.198,389.691c0.184,0.087,0.402,0.093,0.591-0.003c0.988-0.503,6.167-1.582,9.263,1.153
          c0.14-0.601-0.03-1.244-0.54-1.916c-3.558-1.931-8.208-0.966-9.365-0.375c-0.315,0.16-0.427,0.547-0.25,0.859
          C379.968,389.537,380.074,389.633,380.198,389.691" />
          <path fill="#FFA9A7" d="M397.727,379.198c0,0,2.646,15.958,5.588,22.151c2.942,6.192,4.454,9.182,8.507,13.867
          c0.883,1.02,2.894,2.916,3.615,3.741c0.643,0.734,0.746,1.584-0.169,2.458c-1.802,1.724-9.443,2.133-13.726-1.077
          s-8.182-10.048-10.735-12.632c-0.959-0.972-1.862-1.963-2.722-3.017c-0.828-1.016-0.602-1.98-0.675-3.118
          c-0.035-0.55,0.044-2.052,0.044-2.052c-0.586-2.687-2.172-6.903-3.014-9.522L397.727,379.198z" />
          <path fill="#262626" d="M385.944,411.579c0,0-0.077,1.28,0.196,1.947c0.427,1.04,4.298,3.302,5.771,4.095
          c1.475,0.794,3.074,1.904,4.386,5.004c1.403,3.313,4.315,5.763,7.679,7.453c3.364,1.689,8.39,2.905,14.53,2.024
          c6.139-0.882,6.761-4.459,6.761-4.459s0.441-3.372-1.059-3.96C422.71,423.095,385.944,411.579,385.944,411.579" />
          <path fill="#5D5D5D" d="M387.235,398.686c-0.166,0.071-0.476,0.161-0.657,0.865c-0.068,0.267,0.092,3.139-0.122,4.788
          c-0.229,1.764-1.209,5.056-0.512,7.24c3.117,3.389,8.15,4.395,10.481,7.882s4.45,7.813,12.385,9.683
          c7.934,1.87,14.086-0.455,15.644-2.676c1.557-2.222,0.284-4.412-4.738-7.358c-5.331-3.127-12.175-12.123-12.178-12.127
          c-0.947-1.245-1.789-2.633-2.564-3.99c-0.755-1.318-1.046-2.969-1.59-4.396c-0.257-0.676-0.562-1.388-1.17-1.78
          c-0.605-0.392-1.38-0.376-2.103-0.348c-1.154,0.046-2.319,0.094-3.436,0.395c-1.115,0.301-2.191,0.881-2.878,1.812
          c-0.496,0.672-0.771,1.498-1.334,2.115c-0.752,0.829-2,1.167-3.069,0.831c-1.574-0.497-1.843-1.484-1.994-2.24
          C387.371,399.369,387.235,398.686,387.235,398.686" />
          <path fill="#F9F9F9" d="M403.702,427.055c0,0,0.09-5.599,6.224-8.465c6.134-2.867,9.724-0.752,11.52,0.641
          c2.14,1.658,5.162,5.067,3.171,7.396c-2.151,2.515-5.491,3.627-11.641,3.482C406.827,429.963,403.702,427.055,403.702,427.055" />
          <path fill="#F9F9F9" d="M396.111,408.085c0.213,0.001,0.423-0.092,0.558-0.268c0.719-0.928,5.229-4.337,9.42-3.008
          c0.234-0.481-0.104-0.999-0.955-1.551c-4.434-0.556-8.714,2.646-9.58,3.769c-0.228,0.293-0.161,0.709,0.146,0.926
          C395.824,408.041,395.968,408.084,396.111,408.085" />
          <path fill="#F9F9F9" d="M408.402,408.08c-0.045-0.642-0.704-1.354-1.147-1.516c-4.226-0.238-8.195,2.752-9.027,3.827
          c-0.228,0.293-0.161,0.708,0.146,0.926c0.125,0.088,0.269,0.13,0.411,0.131c0.213,0.001,0.423-0.091,0.558-0.266
          C400.041,410.28,404.321,407.052,408.402,408.08" />
          <path fill="#F9F9F9" d="M414.805,415.174c-0.327-0.761-0.945-1.377-1.829-1.846c-4.365-0.444-8.545,2.686-9.4,3.791
          c-0.228,0.294-0.161,0.709,0.146,0.927c0.125,0.089,0.269,0.131,0.412,0.131c0.212,0.001,0.422-0.092,0.558-0.267
          C405.449,416.931,410.432,413.191,414.805,415.174" />
          <path fill="#F9F9F9" d="M401.46,414.813c0.212,0.001,0.422-0.091,0.557-0.266c0.711-0.919,5.13-4.253,9.279-3.048
          c-0.135-0.63-0.583-1.163-1.367-1.57c-4.228-0.238-8.197,2.753-9.027,3.827c-0.228,0.294-0.161,0.709,0.146,0.926
          C401.173,414.771,401.316,414.813,401.46,414.813" />
          <path fill="#548EED" d="M362.516,288.318c6.543,4.796,29.33,17.95,38.583,26.516c2.673,2.474,3.553,5.73,2.896,12.83
          c-0.894,9.634-8.465,42.467-8.465,42.467c-1.042,1.853-2.876-1.664-2.876-1.664l-36.108-45.841L362.516,288.318z" />
          <g>
            <defs>
              <rect id="SVGID_25_" x="356.546" y="288.319" width="47.657" height="82.351" />
            </defs>
            <clipPath id="SVGID_26_">
              <use xlinkHref="#SVGID_25_" overflow="visible" />
            </clipPath>
            <path clipPath="url(#SVGID_26_)" fill="#224149" d="M362.516,288.318c6.543,4.796,29.33,17.95,38.583,26.516
            c2.673,2.474,3.553,5.73,2.896,12.83c-0.894,9.634-8.465,42.467-8.465,42.467c-1.042,1.853-2.876-1.664-2.876-1.664
            l-36.108-45.841L362.516,288.318z" />
          </g>
          <g opacity="0.3">
            <g>
              <defs>
                <rect id="SVGID_27_" x="358.621" y="296.459" width="35.356" height="63.448" />
              </defs>
              <clipPath id="SVGID_28_">
                <use xlinkHref="#SVGID_27_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_28_)" fill="#548EED" d="M393.964,359.907c-8.354-6.981-35.343-49.563-35.343-49.563
              s0.007-0.007,0.014-0.013c3.676-3.105,5.571-13.872,5.571-13.872c1.457,4.154-0.082,10.418-1.214,12.708
              c2.126,1.84,14.133,11.715,22.475,18.317c2.092,1.656,4.23,3.701,4.947,7.469C392.133,343.985,394.161,358.08,393.964,359.907" />
            </g>
          </g>
          <path fill="#548EED" d="M362.516,288.318L362.516,288.318l-46.479,10.291c2.269,8.531,2.916,12.938,5.285,17.431
          c2.377,4.508,5.009,9.314,17.099,15.267c11.64,5.731,25.857,11.634,28.702,12.986c2.144,1.02,3.165,5.306,4.135,10.863
          c1.015,5.828,1.107,10.39,6.873,22.621c5.646,11.979,6.799,14.566,6.799,14.566s8.735,0.909,14.533-4.192
          c0,0-7.911-41.066-9.598-48.46c-0.941-4.129-0.961-6.306-6.088-10.844c-4.396-3.889-24.026-18.748-24.026-18.748
          S367.546,299.628,362.516,288.318" />
          <g>
            <defs>
              <rect id="SVGID_29_" x="316.035" y="288.319" width="83.427" height="104.088" />
            </defs>
            <clipPath id="SVGID_30_">
              <use xlinkHref="#SVGID_29_" overflow="visible" />
            </clipPath>
            <path clipPath="url(#SVGID_30_)" fill="#2B454E" d="M362.516,288.318L362.516,288.318l-46.479,10.291
            c2.269,8.531,2.916,12.938,5.285,17.431c2.377,4.508,5.009,9.314,17.099,15.267c11.64,5.731,25.857,11.634,28.702,12.986
            c2.144,1.02,3.165,5.306,4.135,10.863c1.015,5.828,1.107,10.39,6.873,22.621c5.646,11.979,6.799,14.566,6.799,14.566
            s8.735,0.909,14.533-4.192c0,0-7.911-41.066-9.598-48.46c-0.941-4.129-0.961-6.306-6.088-10.844
            c-4.396-3.889-24.026-18.748-24.026-18.748S367.546,299.628,362.516,288.318" />
          </g>
          <path fill="#FFA9A7" d="M415.941,211.105c-0.781-0.078-1.586,0.125-2.303,0.463c-0.85,0.399-1.6,0.979-2.355,1.535
          c-1.156,0.852-2.343,1.662-3.557,2.427c-1.08,0.682-2.219,1.444-3.505,1.657c-0.431,0.07-1.348,0.086-0.879-0.567
          c0.611-0.853,1.299-1.663,1.756-2.608c0.457-0.943,0.662-2.074,0.259-3.043c-0.122-0.291-0.312-0.574-0.599-0.707
          c-0.406-0.188-0.613,0.029-0.847,0.297c-0.245,0.279-0.538,0.519-0.82,0.767c-0.58,0.512-1.16,1.026-1.732,1.546
          c-3.345,3.038-7.411,5.671-8.842,10.059c-0.207,0.635-1.154,3.529-1.185,8.348s-0.315,21.59-0.315,21.59
          s-13.319-11.203-19.098-16.031c-2.784-2.328-6.678-5.154-10.967-5.561c-3.036-0.289-4.808-0.271-4.808-0.271l9.085,22.024
          c0,0,17.178,12.779,25.414,17.18c7.433,3.971,11.048,1.111,11.946-0.973c1.488-3.454,3.017-5.073,0.921-24.353
          c-0.15-1.492-0.335-5.76-0.503-7.25c-0.217-3.111,0.136-6.275,1.342-6.949c2.218-1.244,4.39-2.6,6.302-4.275
          c1.259-1.104,2.403-2.348,3.332-3.744c0.389-0.585,1.066-1.436,1.14-2.15c0.074-0.726-0.245-1.494-0.023-2.209
          c0.149-0.49,0.479-0.888,0.602-1.41c0.251-1.062,0.159-2.281,0.835-3.201c0.372-0.506,0.991-0.963,0.905-1.584
          c-0.067-0.498-0.58-0.813-1.068-0.932C416.231,211.145,416.087,211.119,415.941,211.105" />
          <path fill="#FF8F8F" d="M402.354,219.47c-0.374,2.599-2.229,5.847-3.473,6.937c0,0,2.076-1.18,2.951-2.992
          C402.792,221.427,402.685,220.416,402.354,219.47" />
          <path fill="#548EED" d="M355.229,230.559c4.637,0.203,8.25,0.306,12.203,2.588c3.953,2.283,21.607,17.06,21.607,17.06
          s0.174-7.522,0.625-11.487c0.313-2.746,0.769-3.611,1.626-3.474c0,0,1.531,2.347,5.869,2.672c4.338,0.326,5.795-1.475,5.795-1.475
          s1.266-0.298,1.721,1.475s1.273,20.992,0.481,26.529c-0.791,5.538-3.272,8.11-6.599,8.89c-3.326,0.78-11.035-3.881-20.294-10.107
          c-9.259-6.228-19.313-12.031-19.313-12.031L355.229,230.559z" />
          <g>
            <defs>
              <rect id="SVGID_31_" x="355.229" y="230.559" width="50.26" height="42.863" />
            </defs>
            <clipPath id="SVGID_32_">
              <use xlinkHref="#SVGID_31_" overflow="visible" />
            </clipPath>
            <path opacity="0.7" clipPath="url(#SVGID_32_)" d="M355.229,230.559c4.637,0.203,8.25,0.306,12.203,2.588
            c3.953,2.283,21.607,17.06,21.607,17.06s0.174-7.522,0.625-11.487c0.313-2.746,0.769-3.611,1.626-3.474
            c0,0,1.531,2.347,5.869,2.672c4.338,0.326,5.795-1.475,5.795-1.475s1.266-0.298,1.721,1.475s1.273,20.992,0.481,26.529
            c-0.791,5.538-3.272,8.11-6.599,8.89c-3.326,0.78-11.035-3.881-20.294-10.107c-9.259-6.228-19.313-12.031-19.313-12.031
            L355.229,230.559z" />
          </g>
          <g opacity="0.1">
            <g>
              <defs>
                <rect id="SVGID_33_" x="386.878" y="248.463" width="2.542" height="12.532" />
              </defs>
              <clipPath id="SVGID_34_">
                <use xlinkHref="#SVGID_33_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_34_)" fill="#548EED" d="M389.039,250.206c-0.753,5.158,0.381,10.789,0.381,10.789
              s-3.386-6.542-2.343-12.532L389.039,250.206z" />
            </g>
          </g>
          <path fill="#407CD3" d="M317.111,234.619c-0.764,4.051-8.461,42.549,0.294,71.258c15.764,1.859,43.45-5.378,47.602-15.812
          c0,0-2.798-9.9-4.011-14.741c0.257-7.942-0.003-23.1-0.163-27.79c-0.317-9.294-1.77-16.482-6.38-17.163
          c0,0-9.258-0.364-14.861-0.135c-4.84,0.197-8.477,0.25-8.477,0.25L317.111,234.619z" />
          <path opacity="0.7" fill="#21292B" d="M340.268,295.811c-1.019-2.259-19.73-13.812-19.73-13.812s0.285-5.352,1.399-15.198
          c1.114-9.846,1.443-21.648-2.105-27.578l-3.578,0.139c-2.023,11.943-6.137,42.613,1.152,66.516
          c8.404,0.991,20.197-0.604,30.006-3.89C345.603,300.773,341.078,297.611,340.268,295.811" />
          <path fill="#0F0F0F" d="M329.492,221.449c0.03-1.99-3.787-7.941-5.516-12.648c-2.891-7.871-3.143-14.936,3.99-18.004
          c0,0,4.46-7.689,15.703-8.073c11.242-0.382,19.885,9.108,16.15,21.797L329.492,221.449z" />
          <path fill="#FFA9A7" d="M332.271,209.291c-1.468,0-2.002-2.107-3.01-3.24c-1.007-1.131-4.48-2.404-6.719,0.835
          c-2.076,3.009-0.058,7.341,2.209,9.019c2.855,2.111,4.825,0.074,4.825,0.074l-0.259,15.889c0,0,0.506,3.523,6.997,5.568
          c7.496,2.361,13.597-1.584,9.132-6.531l0.166-4.186c2.129,0.481,5.038,0.74,7.248,0.404c2.5-0.38,4.503-2.264,5.788-6.118
          c2.509-7.526,1.812-17.562-0.777-26.723c-2.474,1.956-13.919,5.624-24.228,1.987C334.008,202.6,333.705,209.291,332.271,209.291" />
          <path fill="#262626" d="M328.197,214.195c0,0.515-0.416,0.931-0.931,0.931c-0.514,0-0.93-0.416-0.93-0.931
          c0-0.514,0.416-0.93,0.93-0.93C327.781,213.266,328.197,213.682,328.197,214.195" />
          <path fill="#FF8F8F" d="M345.613,226.719c0,0-6.256-1.826-8.724-3.557c-1.821-1.277-3.443-3.311-3.837-4.377
          c0,0,0.937,3.354,2.644,5.293c2.985,3.393,9.835,4.96,9.835,4.96L345.613,226.719z" />
          <path fill="#262626" d="M345.109,209.205c0.017,0.895-0.667,1.629-1.526,1.643c-0.86,0.015-1.571-0.699-1.588-1.593
          s0.667-1.629,1.527-1.643C344.382,207.6,345.093,208.313,345.109,209.205" />
          <path fill="#262626" d="M343.408,203.895l-3.366,1.773c-0.473-0.965-0.103-2.146,0.827-2.637S342.936,202.928,343.408,203.895" />
          <path fill="#FF8F8F" d="M344.621,217.391l5.354,1.707c-0.527,1.498-2.154,2.332-3.633,1.859
          C344.863,220.486,344.093,218.889,344.621,217.391" />
          <path fill="#262626" d="M357.818,203.826l-3.698-1.013c0.244-1.04,1.27-1.655,2.29-1.376
          C357.432,201.718,358.063,202.787,357.818,203.826" />
          <path fill="#262626" d="M356.862,207.977c0.017,0.893-0.667,1.629-1.527,1.643c-0.859,0.014-1.57-0.699-1.587-1.594
          c-0.018-0.893,0.667-1.629,1.526-1.642C356.134,206.369,356.845,207.082,356.862,207.977" />
          <path fill="#0F0F0F" d="M358.585,194.281c3.456-2.463,5.38-5.758,4.462-10.293c-1.349,1.354-5.13,1.597-8.438,0.019
          c-3.528-1.685-9.125-3.97-16.957-2.453c-6.107,1.183-9.686,5.827-9.686,9.243c1.195,2.445,2.84,3.748,5.677,5.473
          c0,0,5.856,4.792,14.739,4.243c5.715-0.354,8.68-3.14,8.68-3.14s-3.193,0.281-4.688-0.711
          C352.375,196.662,356.001,196.123,358.585,194.281" />
          <polygon fill="#FF8F8F" points="350.165,215.404 354.935,213.229 348.84,204.826 	" />
          <g opacity="0.15">
            <g>
              <defs>
                <rect id="SVGID_35_" x="335.689" y="297.24" width="66.858" height="40.868" />
              </defs>
              <clipPath id="SVGID_36_">
                <use xlinkHref="#SVGID_35_" overflow="visible" />
              </clipPath>
              <path clipPath="url(#SVGID_36_)" fill="#548EED" d="M402.548,316.535c-0.407-0.62-0.886-1.181-1.449-1.701
              c-5.558-5.146-15.997-11.944-24.868-17.595l-40.355,23.303c-0.247,0.143-0.247,0.5,0,0.643l29.309,16.924L402.548,316.535z" />
            </g>
          </g>
          <path fill="#5D5D5D" d="M414.784,304.687v0.652c0,0.956-0.51,1.841-1.34,2.323l-46.922,27.089c-0.411,0.241-0.88,0.354-1.335,0.354
          c-0.466,0-0.93-0.115-1.35-0.357l-25.576-14.718c-0.822-0.474-1.338-1.367-1.338-2.323v-0.902h0.007v0.009
          c0,0.116,0.053,0.223,0.169,0.295l28.088,16.219L414.784,304.687z" />
          <path fill="#262626" d="M365.184,333.323v1.785c0.461,0,0.926-0.118,1.339-0.357l46.922-27.09c0.831-0.478,1.343-1.366,1.343-2.324
          v-0.652L365.184,333.323z" />
          <path fill="#D3D3D3" d="M414.787,304.685l-27.821-16.062c-0.494-0.285-1.104-0.285-1.599,0l-48.267,27.87
          c-0.235,0.137-0.235,0.479,0,0.616l28.082,16.216L414.787,304.685z" />
          <polygon fill="#5D5D5D" points="364.928,330.963 349.922,322.308 393.8,296.976 408.883,305.584 	" />
          <polygon fill="#262626" points="408.883,305.584 408.31,305.914 393.798,297.634 350.497,322.639 349.925,322.309 393.798,296.974 
            " />
          <polygon fill="#262626" points="357.553,306.887 357.829,307.046 365.184,311.292 378.264,303.741 378.536,303.585 
          370.904,299.181 	" />
          <polygon fill="#FFFFFF" points="357.829,307.047 365.183,311.292 378.264,303.74 370.904,299.494 	" />
          <path fill="#FFA9A7" d="M371.787,312.144c-2.104-2.088-5.157-4.98-7.722-5.83c-0.331-0.106-0.829-0.337-1.804-0.562
          c0.885-0.174,1.773-0.234,2.637-0.525c0.856-0.289,1.673-0.763,2.227-1.478c0.223-0.287,0.38-0.745,0.099-0.976
          c-0.101-0.083-0.233-0.13-0.365-0.135c-1.922-0.058-3.437-0.356-5.182-0.482c-0.763-0.055-1.619-0.157-2.354,0.043
          c-0.716,0.195-1.431,0.403-2.132,0.646c-3.188,1.104-6.095,2.097-10.608,1.045l-8.122-4.333c0,0-1.736-0.434-4.293,3.897
          c-2.558,4.33-1.063,6.709-1.063,6.709l9.124,3.149c0.044,0.017,0.085,0.032,0.131,0.051c1.004,0.376,1.907,1.16,4.306,1.896
          c2.843,0.872,6.479-0.159,8.95-0.291c2.47-0.132,6.656,1.011,8.948,1.94c2.291,0.93,5.042-2.599,6.19-2.175
          C371.902,315.158,373.891,314.229,371.787,312.144" />
          <path fill="#FF8F8F" d="M361.197,304.393c-1.663-0.169-3.496,0.566-3.496,0.566s2.054,0.043,4.561,0.794
          c0.885-0.175,1.773-0.236,2.637-0.527c0.756-0.254,1.48-0.654,2.021-1.235C365.537,304.929,362.86,304.562,361.197,304.393" />
          <path fill="#548EED" d="M317.111,234.619c2.312,0.914,5.318,10.547,3.466,19.817l-4.302,28.985c0,0,5.27,3.121,12.664,7.7
          c5.125,3.174,9.519,6.084,9.521,8.435c-3.356,1.206-6.372,7.385-5.356,10.605c-2.292,2.717-23.45-9.013-30.847-14.072
          c-3.431-2.346-5.16-3.271-3.841-13.861c1.461-11.721,2.462-24.742,4.745-33.431C305.544,239.728,308.197,236.207,317.111,234.619" />
          <g>
            <defs>
              <rect id="SVGID_37_" x="297.973" y="234.62" width="40.487" height="75.945" />
            </defs>
            <clipPath id="SVGID_38_">
              <use xlinkHref="#SVGID_37_" overflow="visible" />
            </clipPath>
            <path opacity="0.7" clipPath="url(#SVGID_38_)" d="M317.111,234.619c2.312,0.914,5.318,10.547,3.466,19.817l-4.302,28.985
            c0,0,5.27,3.121,12.664,7.7c5.125,3.174,9.519,6.084,9.521,8.435c-3.356,1.206-6.372,7.385-5.356,10.605
            c-2.292,2.717-23.45-9.013-30.847-14.072c-3.431-2.346-5.16-3.271-3.841-13.861c1.461-11.721,2.462-24.742,4.745-33.431
            C305.544,239.728,308.197,236.207,317.111,234.619" />
          </g>
          <path fill="#E8E8E8" d="M365.94,333.719c0.888,0.344,1.897,0.277,2.737-0.211l46.716-26.972c0.822-0.474,1.386-1.287,1.545-2.221
          l5.114-30.499c0.028-0.174,0.043-0.348,0.043-0.521c0-0.85-0.339-1.663-0.939-2.259l-48.547,28.033
          c-0.606,0.352-1.024,0.953-1.142,1.644L365.94,333.719z" />
          <path fill="#5D5D5D" d="M365.184,333.324l0.32,0.184c0.141,0.08,0.286,0.15,0.437,0.212l5.527-33.007
          c0.117-0.69,0.535-1.291,1.142-1.645l48.547-28.032c-0.188-0.188-0.41-0.353-0.648-0.493l-48.646,28.085
          c-0.605,0.352-1.024,0.953-1.142,1.644L365.184,333.324z" />
          <path fill="#262626" d="M371.919,299.69l-0.753-0.435c-0.223,0.297-0.381,0.641-0.445,1.016l-5.537,33.054l0.319,0.183
          c0.142,0.079,0.287,0.15,0.438,0.211l5.527-33.006C371.532,300.335,371.693,299.989,371.919,299.69" />
          <path fill="#FFFFFF" d="M392.251,305.564c0.342-2.042,2.45-4.754,4.708-6.058c2.259-1.303,3.812-0.705,3.469,1.337
          s-2.45,4.754-4.709,6.059C393.461,308.205,391.908,307.606,392.251,305.564" />
        </g>
        <g id="Question_Marks_1_">
          <g id="Question_Marks_2_">
            <g>
              <path fill="#548EED" d="M421.104,107.367c1.748,0.387,3.396,1.006,4.942,1.855s2.858,1.891,3.931,3.123
              c1.073,1.231,1.846,2.631,2.317,4.199c0.472,1.566,0.506,3.262,0.103,5.083c-0.329,1.487-0.848,2.729-1.555,3.722
              c-0.709,0.994-1.521,1.848-2.439,2.561s-1.887,1.338-2.901,1.873c-1.017,0.536-1.979,1.045-2.883,1.525
              c-0.907,0.482-1.728,1.014-2.459,1.592c-0.733,0.579-1.245,1.266-1.538,2.059c-0.166,0.393-0.411,0.708-0.736,0.947
              c-0.327,0.241-0.694,0.314-1.103,0.225l-4.239-0.939c-0.409-0.09-0.739-0.319-0.988-0.687c-0.25-0.367-0.312-0.75-0.184-1.151
              c0.396-1.434,0.983-2.64,1.766-3.617c0.782-0.977,1.65-1.818,2.606-2.523s1.949-1.313,2.979-1.826
              c1.029-0.514,1.988-1.013,2.877-1.499c0.887-0.485,1.645-1.001,2.271-1.544c0.628-0.543,1.028-1.206,1.201-1.987
              c0.313-1.412-0.024-2.706-1.013-3.881c-0.988-1.174-2.468-1.979-4.438-2.416c-3.16-0.7-5.834,0.366-8.021,3.197
              c-0.276,0.368-0.54,0.631-0.788,0.79c-0.251,0.16-0.618,0.186-1.102,0.078l-4.517-1c-0.334-0.074-0.599-0.25-0.79-0.526
              c-0.192-0.276-0.247-0.601-0.165-0.973c0.324-1.293,1.02-2.533,2.082-3.722c1.062-1.188,2.357-2.188,3.882-3.002
              c1.526-0.812,3.232-1.381,5.117-1.704C417.207,106.875,419.134,106.93,421.104,107.367z M416.321,139.539
              c0.408,0.09,0.729,0.317,0.959,0.681c0.232,0.363,0.303,0.749,0.212,1.158l-1.087,4.907c-0.091,0.41-0.317,0.73-0.681,0.961
              c-0.363,0.232-0.748,0.303-1.157,0.212l-4.796-1.062c-0.41-0.092-0.73-0.317-0.962-0.682c-0.232-0.363-0.303-0.748-0.212-1.158
              l1.087-4.908c0.091-0.408,0.317-0.729,0.681-0.961c0.364-0.23,0.749-0.302,1.159-0.211L416.321,139.539z" />
            </g>
            <g>
              <path fill="#548EED" d="M426.443,154.277c1.219-0.199,2.434-0.226,3.644-0.076c1.209,0.148,2.315,0.479,3.319,0.994
              c1.003,0.517,1.856,1.216,2.56,2.1c0.705,0.885,1.16,1.963,1.368,3.232c0.169,1.038,0.154,1.968-0.045,2.785
              c-0.199,0.818-0.502,1.574-0.908,2.267c-0.406,0.691-0.866,1.34-1.381,1.943c-0.514,0.604-1,1.175-1.457,1.716
              c-0.458,0.541-0.848,1.092-1.169,1.65c-0.322,0.558-0.475,1.129-0.459,1.712c-0.006,0.295-0.083,0.56-0.229,0.797
              c-0.148,0.237-0.365,0.379-0.649,0.426l-2.958,0.483c-0.286,0.047-0.556-0.016-0.811-0.188c-0.254-0.171-0.392-0.401-0.412-0.691
              c-0.113-1.021-0.045-1.943,0.207-2.771c0.25-0.826,0.592-1.589,1.024-2.285c0.432-0.696,0.913-1.341,1.442-1.934
              c0.529-0.592,1.016-1.158,1.461-1.698c0.444-0.537,0.798-1.062,1.062-1.57c0.263-0.51,0.351-1.036,0.262-1.582
              c-0.161-0.984-0.709-1.728-1.644-2.229c-0.935-0.5-2.089-0.638-3.464-0.413c-2.205,0.36-3.646,1.729-4.324,4.104
              c-0.083,0.306-0.184,0.542-0.304,0.708c-0.119,0.167-0.348,0.277-0.685,0.332l-3.151,0.516c-0.234,0.038-0.448-0.007-0.643-0.135
              c-0.194-0.129-0.312-0.322-0.354-0.582c-0.123-0.911,0.005-1.885,0.383-2.919c0.376-1.034,0.95-2.007,1.72-2.918
              c0.771-0.912,1.719-1.712,2.844-2.402C423.818,154.958,425.068,154.501,426.443,154.277z M431.611,176.125
              c0.285-0.046,0.549,0.018,0.791,0.191c0.241,0.174,0.385,0.404,0.432,0.688l0.56,3.426c0.047,0.285-0.017,0.549-0.19,0.79
              c-0.174,0.242-0.403,0.385-0.688,0.432l-3.347,0.547c-0.285,0.047-0.549-0.016-0.79-0.189c-0.242-0.174-0.386-0.403-0.433-0.688
              l-0.56-3.426c-0.046-0.284,0.017-0.548,0.19-0.79c0.174-0.241,0.403-0.386,0.688-0.433L431.611,176.125z" />
            </g>
            <g opacity="0.2">
              <path d="M426.443,154.277c1.219-0.199,2.434-0.226,3.644-0.076c1.209,0.148,2.315,0.479,3.319,0.994
              c1.003,0.517,1.856,1.216,2.56,2.1c0.705,0.885,1.16,1.963,1.368,3.232c0.169,1.038,0.154,1.968-0.045,2.785
              c-0.199,0.818-0.502,1.574-0.908,2.267c-0.406,0.691-0.866,1.34-1.381,1.943c-0.514,0.604-1,1.175-1.457,1.716
              c-0.458,0.541-0.848,1.092-1.169,1.65c-0.322,0.558-0.475,1.129-0.459,1.712c-0.006,0.295-0.083,0.56-0.229,0.797
              c-0.148,0.237-0.365,0.379-0.649,0.426l-2.958,0.483c-0.286,0.047-0.556-0.016-0.811-0.188c-0.254-0.171-0.392-0.401-0.412-0.691
              c-0.113-1.021-0.045-1.943,0.207-2.771c0.25-0.826,0.592-1.589,1.024-2.285c0.432-0.696,0.913-1.341,1.442-1.934
              c0.529-0.592,1.016-1.158,1.461-1.698c0.444-0.537,0.798-1.062,1.062-1.57c0.263-0.51,0.351-1.036,0.262-1.582
              c-0.161-0.984-0.709-1.728-1.644-2.229c-0.935-0.5-2.089-0.638-3.464-0.413c-2.205,0.36-3.646,1.729-4.324,4.104
              c-0.083,0.306-0.184,0.542-0.304,0.708c-0.119,0.167-0.348,0.277-0.685,0.332l-3.151,0.516c-0.234,0.038-0.448-0.007-0.643-0.135
              c-0.194-0.129-0.312-0.322-0.354-0.582c-0.123-0.911,0.005-1.885,0.383-2.919c0.376-1.034,0.95-2.007,1.72-2.918
              c0.771-0.912,1.719-1.712,2.844-2.402C423.818,154.958,425.068,154.501,426.443,154.277z M431.611,176.125
              c0.285-0.046,0.549,0.018,0.791,0.191c0.241,0.174,0.385,0.404,0.432,0.688l0.56,3.426c0.047,0.285-0.017,0.549-0.19,0.79
              c-0.174,0.242-0.403,0.385-0.688,0.432l-3.347,0.547c-0.285,0.047-0.549-0.016-0.79-0.189c-0.242-0.174-0.386-0.403-0.433-0.688
              l-0.56-3.426c-0.046-0.284,0.017-0.548,0.19-0.79c0.174-0.241,0.403-0.386,0.688-0.433L431.611,176.125z" />
            </g>
            <g>
              <path fill="#548EED" d="M454.719,159.661c0.279,0.199,0.448,0.472,0.508,0.819c0.058,0.348-0.012,0.661-0.21,0.94l-2.382,3.351
              c-0.199,0.28-0.472,0.449-0.819,0.507c-0.348,0.06-0.661-0.011-0.94-0.209l-3.274-2.328c-0.28-0.199-0.448-0.471-0.507-0.818
              c-0.06-0.349,0.011-0.661,0.21-0.94l2.382-3.352c0.198-0.279,0.47-0.448,0.819-0.507c0.347-0.059,0.659,0.011,0.939,0.21
              L454.719,159.661z M468.572,136.949c1.192,0.847,2.237,1.838,3.132,2.971c0.895,1.134,1.552,2.335,1.966,3.604
              c0.416,1.271,0.552,2.57,0.408,3.901c-0.144,1.332-0.657,2.619-1.542,3.863c-0.722,1.016-1.508,1.785-2.356,2.308
              c-0.85,0.524-1.734,0.908-2.653,1.152c-0.92,0.245-1.848,0.407-2.782,0.488c-0.938,0.08-1.823,0.156-2.659,0.23
              s-1.623,0.213-2.358,0.416c-0.737,0.202-1.342,0.557-1.814,1.061c-0.25,0.243-0.535,0.402-0.857,0.479s-0.622,0.016-0.901-0.183
              l-2.895-2.058c-0.279-0.198-0.455-0.476-0.526-0.832s0.004-0.665,0.229-0.927c0.756-0.954,1.582-1.675,2.483-2.162
              c0.9-0.486,1.822-0.844,2.768-1.071c0.943-0.227,1.886-0.37,2.826-0.427c0.938-0.059,1.82-0.13,2.645-0.213
              c0.823-0.083,1.557-0.229,2.204-0.439c0.645-0.21,1.158-0.581,1.537-1.114c0.686-0.965,0.843-2.046,0.473-3.246
              c-0.37-1.199-1.228-2.277-2.572-3.233c-2.159-1.534-4.514-1.583-7.063-0.147c-0.326,0.189-0.608,0.305-0.846,0.345
              c-0.24,0.04-0.523-0.057-0.854-0.291l-3.085-2.193c-0.229-0.162-0.371-0.378-0.428-0.647c-0.057-0.271,0.006-0.531,0.186-0.785
              c0.656-0.871,1.574-1.585,2.754-2.142s2.473-0.898,3.882-1.024c1.407-0.126,2.873-0.012,4.396,0.346
              C465.791,135.334,467.226,135.992,468.572,136.949z" />
            </g>
            <g opacity="0.6">
              <path fill="#FFFFFF" d="M454.719,159.661c0.279,0.199,0.448,0.472,0.508,0.819c0.058,0.348-0.012,0.661-0.21,0.94l-2.382,3.351
              c-0.199,0.28-0.472,0.449-0.819,0.507c-0.348,0.06-0.661-0.011-0.94-0.209l-3.274-2.328c-0.28-0.199-0.448-0.471-0.507-0.818
              c-0.06-0.349,0.011-0.661,0.21-0.94l2.382-3.352c0.198-0.279,0.47-0.448,0.819-0.507c0.347-0.059,0.659,0.011,0.939,0.21
              L454.719,159.661z M468.572,136.949c1.192,0.847,2.237,1.838,3.132,2.971c0.895,1.134,1.552,2.335,1.966,3.604
              c0.416,1.271,0.552,2.57,0.408,3.901c-0.144,1.332-0.657,2.619-1.542,3.863c-0.722,1.016-1.508,1.785-2.356,2.308
              c-0.85,0.524-1.734,0.908-2.653,1.152c-0.92,0.245-1.848,0.407-2.782,0.488c-0.938,0.08-1.823,0.156-2.659,0.23
              s-1.623,0.213-2.358,0.416c-0.737,0.202-1.342,0.557-1.814,1.061c-0.25,0.243-0.535,0.402-0.857,0.479s-0.622,0.016-0.901-0.183
              l-2.895-2.058c-0.279-0.198-0.455-0.476-0.526-0.832s0.004-0.665,0.229-0.927c0.756-0.954,1.582-1.675,2.483-2.162
              c0.9-0.486,1.822-0.844,2.768-1.071c0.943-0.227,1.886-0.37,2.826-0.427c0.938-0.059,1.82-0.13,2.645-0.213
              c0.823-0.083,1.557-0.229,2.204-0.439c0.645-0.21,1.158-0.581,1.537-1.114c0.686-0.965,0.843-2.046,0.473-3.246
              c-0.37-1.199-1.228-2.277-2.572-3.233c-2.159-1.534-4.514-1.583-7.063-0.147c-0.326,0.189-0.608,0.305-0.846,0.345
              c-0.24,0.04-0.523-0.057-0.854-0.291l-3.085-2.193c-0.229-0.162-0.371-0.378-0.428-0.647c-0.057-0.271,0.006-0.531,0.186-0.785
              c0.656-0.871,1.574-1.585,2.754-2.142s2.473-0.898,3.882-1.024c1.407-0.126,2.873-0.012,4.396,0.346
              C465.791,135.334,467.226,135.992,468.572,136.949z" />
            </g>
          </g>
        </g>
        <g id="Character">
        </g>
      </svg>
    )
}