import './App.css';
import Routes from './routes/Routes';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Routes />
    </SnackbarProvider>
  );
}

export default App;
