import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CSpinner} from '@coreui/react';
import RestClient from '../api/RestClient'
import queryString from 'query-string';
import VerifyAccountIcon from '../assets/svg/verifyAccount';
import InvalidUrlIcon from '../assets/svg/invalidUrl';
import {defaultHeaders} from '../common/NiarametricsConfig';
// import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Nairalytics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function VerifyEmail(props) {
  const baseUrl = process.env[`REACT_APP_${process.env.REACT_APP_ENV ? (process.env.REACT_APP_ENV).toUpperCase() : "DEV"}_BASE_URL`]
  const { history ,location} = props
  const classes = useStyles();
  const [valid, setvalid] = useState(true)
  const {email_id, new_email_id, token, category} = queryString.parse(location.search);
  //inputs
  const [password, setPassword] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  //loading
  const [isLoading, setisLoading] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [failureMsg, setfailureMsg] = useState("");
  // const [resendMsg, setresendMsg] = useState("");
  // const [resendFailureMsg, setresendFailureMsg] = useState("");

  useEffect(() => {
      if(email_id && new_email_id && token && category){
        setvalid(true)
      }else{
        setvalid(false)
      }
  }, [email_id,new_email_id,token,category])
  //valid inputs
  function validateInputs() {
    let isEmpty =
    password.length &&
      !passwordMsg.length;
    return !isEmpty;
  }
  //clear inputs
  const clearInputs = () => {
    setPassword("");
    setPasswordMsg("");
  };
  //onResend submit
  // const onResend = (e) => {
  //   e.preventDefault();
  //   const config = {};
  //   config.url = `${baseUrl}/bo/api/auth/resendverify/${new_email_id}`;
  //   //Updated
  //   config.headers = {
  //     ...defaultHeaders,
  //   };
  //   RestClient.get(config)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.status === 200 || response.status === 201) {
  //         setresendMsg(response.data.display_message)
  //         setTimeout(() => {
  //           setresendMsg("")
  //         }, 3000);
  //       }else if(response.status === 500){
  //         setresendFailureMsg(response.data.display_message)
  //         setTimeout(() => {
  //           setresendFailureMsg("")
  //         }, 3000);
  //       } 
  //       else {
  //         setresendFailureMsg(response.data.display_message)
  //         setTimeout(() => {
  //           setresendFailureMsg("")
  //         }, 3000);
  //       }
  //     })
  //     .catch((err) => {
  //       setresendFailureMsg(err.message)
  //       setTimeout(() => {
  //         setresendFailureMsg("")
  //       }, 3000);
  //     });
  // };
  //onVerify submit
  const onVerify = (e) => {
    e.preventDefault();
    setisLoading(true);
    const config = {};
    config.url = `${baseUrl}/bo/api/auth/verifyemail?email_id=${email_id.replace("@","%40")}&new_email_id=${new_email_id.replace("@","%40")}&verificationtoken=${token}`;
    //Updated
    config.headers = {
      ...defaultHeaders,
    };
    RestClient.get(config)
      .then((response) => {
        setisLoading(false);
        if (response.status === 200 || response.status === 201) {
          setsuccessMsg(response.data.display_message)
          setTimeout(() => {
            setsuccessMsg("")
          }, 3000);
          login();
        }
        else if(response.status === 500){
          setfailureMsg(response.data.display_message)
          setTimeout(() => {
            setfailureMsg("")
          }, 3000);
        } else {
          setfailureMsg(response.data.display_message)
          setTimeout(() => {
            setfailureMsg("")
          }, 3000);
        }
      })
      .catch((err) => {
        setfailureMsg(err.message)
        setTimeout(() => {
          setfailureMsg("")
        }, 3000);
        setisLoading(false);
      });
  };
  //Login
  const login = () => {
    setisLoading(true);
    const config = {};
    config.url = `${baseUrl}/bo/api/auth/login`;
    //Updated
    config.headers = {
      ...defaultHeaders,
    };
    config.data = {
      email_id : new_email_id,
      password: password,
    };
    RestClient.post(config)
      .then((response) => {
        setisLoading(false);
        if (response.status === 200 || response.status === 201) {
          setfailureMsg("")
          localStorage.setItem("abt", response.data.bearer_token);
          localStorage.setItem("arol", response.data.role);
          setTimeout(() => {
            history.push("/dashboard/data-management");
            clearInputs();
            setisLoading(false);
          }, 1000);
        }
        else if(response.status === 500){
          setfailureMsg(response.data.display_message)
        } else {
          setfailureMsg(response.data.display_message)
        }
      })
      .catch((err) => {
        setfailureMsg(err.message)
        setisLoading(false);
      });
  };
  if(!valid){
    return   <div component="main" maxWidth="xs" style={{backgroundColor: 'rgb(255,255,255)',height:'100vh',display:'flex',justifyContent: 'space-between',flexDirection:'column'}}>
    <div className={classes.paper}>
     <InvalidUrlIcon />
  <h2 style={{ fontWeight: "600",letterSpacing:1,color:'' }}>Oops! Invalid URL</h2>
  <Grid container >
          <Grid item xs style={{display:'flex',justifyContent: 'center',alignSelf: 'center',marginTop:20}}>
            <Link href="/login" variant="body2" style={{color:""}}>
              Go back to login
            </Link>
          </Grid>
  </Grid>
    </div>
    <Box mt={8} style={{marginBottom:10}}>
      <Copyright />
    </Box>
  </div>
  }else{
  return (
    <>
      {/* {resendMsg !== "" && <Alert severity="success">{resendMsg}</Alert>}
      {resendFailureMsg !== "" && <Alert severity="error">{resendFailureMsg}</Alert>} */}
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <VerifyAccountIcon />
        <Typography component="h1" variant="h5" style={{fontWeight:'bold'}}>
          Email Verification
        </Typography>
        <span>Enter your new password to continue</span>
        <form className={classes.form} noValidate>
          <span style={{color: successMsg ? 'green' : 'red'}}>{successMsg ? successMsg : failureMsg && failureMsg}</span>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(e) => {
                if (e.target.value.length) {
                  setPasswordMsg("");
                }
                setPassword(e.target.value);
            }}
            onBlur={(e) => {
                const pattern =
                  // "^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?])(?=.*[0-9])[0-9a-zA-Z!@#$%^&*?]{8,16}$";
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,16})";
                if (!e.target.value.length) {
                  setPasswordMsg("Password cannot be empty");
                  return;
                }
                if (!e.target.value.match(pattern)) {
                  setPasswordMsg("Must follow the password pattern");
                  return;
                }
            }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            style={{color:'white'}}
            disabled={validateInputs()}
            variant="contained"
            color="primary"
            onClick={(e)=>onVerify(e)}
            className={classes.submit}
          >
            {isLoading ? (
              <CSpinner
              style={{ width: "1.5rem", height: "1.5rem",color:'silver' }}
              />
              ) : (
                "Submit"
              )}
          </Button>
          {/* <Grid container>
            <Grid item>
              <button onClick={onResend}
            style={{color:"blueviolet",border:0,backgroundColor:'transparent'}}>
              Don't receive an email? Resend
              </button>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
  }
}