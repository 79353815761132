const getValidErrors = (error) => {
  const errorDetails = error.response;
  if (errorDetails) {
    const { message, status, error: statusText } = errorDetails.data || {};

    return { error: true, statusText, status, message };
  }

  return {
    error: true,
    statusText: "Internal Server Error",
    status: 500,
    message: "Internal Server Error",
  };
};

export default class RestClient {
  static get(config) {
    const { url, headers } = config;

    return fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => {
        const status = response.status;
        const statusText = response.statusText;
        return response
          .json()
          .then((json) => ({ status, statusText, data: json }));
      })
      .catch((error) => {
        return getValidErrors(error);
      });
  }

  static post(config) {
    const { url, headers, data, Filedata } = config;

    return fetch(url, {
      method: "POST",
      headers,
      body: Filedata ? Filedata : JSON.stringify(data),
    })
      .then((response) => {
        const status = response.status;
        const statusText = response.statusText;

        return response
          .json()
          .then((json) => ({ status, statusText, data: json }));
      })
      .catch((error) => {
        return getValidErrors(error);
      });
  }

  static put(config) {
    const { url, headers, data } = config;

    return fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        const status = response.status;
        const statusText = response.statusText;

        return response
          .json()
          .then((json) => ({ status, statusText, data: json }));
      })
      .catch((error) => {
        return getValidErrors(error);
      });
  }

  static delete(config) {
    const { url, headers, data } = config;

    return fetch(url, {
      method: "DELETE",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        const status = response.status;
        const statusText = response.statusText;

        return response
          .json()
          .then((json) => ({ status, statusText, data: json }));
      })
      .catch((error) => {
        return getValidErrors(error);
      });
  }
}
