import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PageNotFoundIcon from '../assets/svg/invalidUrl';
import { makeStyles } from '@material-ui/core/styles';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Nairalytics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PageNotFound(props) {
  const classes = useStyles();

  return (
    <div component="main" maxWidth="xs" style={{backgroundColor: 'rgb(255,255,255)',height:'100vh',width:'100vw',display:'flex',justifyContent: 'space-between',flexDirection:'column'}}>
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
      <PageNotFoundIcon />
    <h2 style={{ fontWeight: "600",letterSpacing:1,color:'' }}>Oops! Page not found</h2>
    <Grid container >
            <Grid item xs style={{display:'flex',justifyContent: 'center',alignSelf: 'center',marginTop:20}}>
              <Link href="/login" variant="body2" style={{color:""}}>
                Go Back to App
              </Link>
            </Grid>
    </Grid>
      </div>
      <Box mt={8} style={{marginBottom:10}}>
        <Copyright />
      </Box>
    </div>
  );
}