import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "./config";
import PageNotFound from "../templates/pageNotFound";
import VerifyEmail from "../templates/verifyEmail";
//auth
const Login = lazy(() => import('../authentication/login'));
const Forgot = lazy(() => import('../authentication/forgot'));
const VerifyAccount = lazy(() => import('../templates/VerifyAccount'));
const ResetPassword = lazy(() => import('../templates/resetPassword'));
const SuccessMsg = lazy(() => import('../templates/emailSuccess'));
//dashboard
const Home = lazy(() => import('../dashboard/home'));
//404
// import Forgot from '../authentication/forgot'
// import Login from '../authentication/login'
// import Home from '../home/home'
// // import Admin from './home/Admin/admin'
// import SuccessMsg from '../templates/emailSuccess';
// import ResetPassword from '../templates/resetPassword';
// import VerifyAccount from '../templates/VerifyAccount';

const Routes = () => {
  function PrivateRoute({ component: Component, ...rest }) {
    const authenticated = localStorage.getItem("abt");
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }

  const PublicRoute = ({ ...props }) => {
    const authenticated = localStorage.getItem("abt");
    const url = window.location.href;
    return authenticated && !url.toString().includes("redirect") ? (
      <Redirect to="/dashboard/data-management" />
    ) : (
      <Route {...props} />
    );
  };

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/* auth */}
        <PublicRoute  path={routes.login} component={Login} />
        <PublicRoute exact path={routes.forgot} component={Forgot} />
        <Route exact path={routes.verifyaccount} component={VerifyAccount} />
        <Route exact path={routes.verifyemail} component={VerifyEmail} />
        <Route exact path={routes.resetpassword} component={ResetPassword} />
        <Route exact path={routes.successmsg} component={SuccessMsg} />
        {/* dashboard */}
        <PrivateRoute  path={routes.home} component={Home} />
        <Redirect exact from="/" to={routes.login} />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
